import { useState, useEffect } from 'react';
import { getCompanies } from '../functions/companiesCache';

export function useCompaniesOptions({ showAll = true }) {
  const [companiesOptions, setCompaniesOptions] = useState([]);
  const [companiesIdCache, setCompaniesIdCache] = useState({});

  useEffect(() => {
    (async () => {
      const cache = await getCompanies();
      setCompaniesIdCache(cache);
    })();
  }, []);

  useEffect(() => {
    setCompaniesOptions([
      ...(showAll ? [{ label: 'הכל', value: 'ALL' }] : []),
      ...Object.entries(companiesIdCache).map(([id, name]) => ({
        label: name,
        value: id,
      })),
    ]);
  }, [companiesIdCache, showAll]);

  return { companiesOptions, companiesIdCache };
}
