import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  Switch,
  Box,
} from '@chakra-ui/react';
import { MultiSelect } from 'chakra-multiselect';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import styled from '@emotion/styled';

import FormSkeleton from './FormSkeleton';
import { useOrganizations } from '../context/OrganizationsContext';
import { formFields, validationSchema } from '../consts/formConsts';

export default function CreateEditOrganizationModal() {
  const {
    isEditMode,
    selectedOrganization,
    companiesOptions,
    loaders: { isCreateEditLoading: isLoading },
    isModalOpen: isOpen,
    onModalClose: onClose,
    onSubmit,
  } = useOrganizations();

  const initialValues = {
    name: isEditMode ? selectedOrganization?.name || '' : '',
    companies: isEditMode ? selectedOrganization?.companies || [] : [],
    isActive: isEditMode ? (selectedOrganization?.isActive ?? false) : true,
    ...(isEditMode ? { id: selectedOrganization?.id } : {}),
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => onSubmit(values)}
      >
        {({ values, setFieldValue }) => {
          const onSwitchChange = (e) => {
            const isChecked = e.target.checked;
            setFieldValue(formFields.isActive.name, isChecked);
            if (!isChecked) {
              setFieldValue(formFields.companies.name, []);
            }
          };

          const onMultiSelectChange = (selected) => {
            setFieldValue(formFields.companies.name, selected);
          };

          return (
            <Form>
              <ModalContent>
                <ModalHeader>{isEditMode ? 'עריכת אירגון' : 'הוספת אירגון חדש'}</ModalHeader>
                <ModalBody>
                  {isLoading ? (
                    <FormSkeleton />
                  ) : (
                    <>
                      <FormControl display="flex" alignItems="center" mt={4}>
                        <FormLabel mb="0">{formFields.isActive.label}</FormLabel>
                        <Field name={formFields.isActive.name}>
                          {({ field }) => (
                            <Switch
                              {...field}
                              isChecked={values.isActive}
                              onChange={onSwitchChange}
                              colorScheme="teal"
                              mr={2}
                            />
                          )}
                        </Field>
                      </FormControl>

                      <FormControl mt={4}>
                        <FormLabel>{formFields.name.label}</FormLabel>
                        <Field
                          name={formFields.name.name}
                          render={({ field }) => (
                            <Input
                              {...field}
                              placeholder={formFields.name.placeholder}
                              width="100%"
                              p="8px"
                              mb="10px"
                            />
                          )}
                        />
                        <ErrorMessage name={formFields.name.name} component={ErrorMessageText} />
                      </FormControl>

                      <FormControl mt={4} isDisabled={!values.isActive}>
                        <FormLabel>{formFields.companies.label}</FormLabel>
                        <Box pointerEvents={values.isActive ? 'auto' : 'none'}>
                          <MultiSelect
                            options={companiesOptions}
                            value={values.companies}
                            onChange={onMultiSelectChange}
                            placeholder={formFields.companies.placeholder}
                          />
                        </Box>
                      </FormControl>
                    </>
                  )}
                </ModalBody>
                <ModalFooter>
                  <Button type="submit" isLoading={isLoading}>
                    אישור
                  </Button>
                  <Button onClick={onClose} variant="outline">
                    סגור
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}

// ----------------------------------- Styled Components -----------------------------------
const ErrorMessageText = styled.div`
  font-size: 12px;
  color: red;
`;
