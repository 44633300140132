import { Skeleton, Flex } from '@chakra-ui/react';

const TableSkeleton = ({ rows = 10 }) => {
  return (
    <Flex p="20px" flexDirection="column" gap="16px">
      {Array.from({ length: rows }).map((_, index) => (
        <Skeleton key={index} height="50px" />
      ))}
    </Flex>
  );
};

export default TableSkeleton;
