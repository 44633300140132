import CompaniesApi from "../api/companies";

let companiesCache = null;

export const getCompanies = async () => {
    if (companiesCache) {
        return companiesCache;
    }

    try {
        const response = await CompaniesApi.getAll();
        companiesCache = response.data.reduce((acc, company) => {
            acc[company.id] = company.name;
            return acc;
        }, {});

        return companiesCache;
    } catch (error) {
        console.error('Error fetching companies:', error);
        return {};
    }
};
