import React from 'react';

import OrganizationsTable from './components/OrganizationsTable';
import CreateEditOrganizationModal from './components/CreateEditOrganizationModal';
import Card from 'components/card/Card';
import { OrganizationsProvider } from './context/OrganizationsContext';
import OrganizationsTopSection from './components/OrganizationsTopSection';

export default function Organizations() {
  return (
    <OrganizationsProvider>
      <Card direction="column" w="100%" px="0px" overflowX={{ sm: 'scroll', lg: 'hidden' }}>
        <OrganizationsTopSection />
        <OrganizationsTable />
      </Card>
      <CreateEditOrganizationModal />
    </OrganizationsProvider>
  );
}
