import { Skeleton, Stack } from '@chakra-ui/react';

const TableSkeleton = ({ rows = 5 }) => {
  return (
    <Stack p="20px">
      {Array.from({ length: rows }).map((_, index) => (
        <Skeleton key={index} height="50px" />
      ))}
    </Stack>
  );
};

export default TableSkeleton;
