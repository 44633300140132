import React, { useState } from "react";
import NewMessageModal from "./NewMessageModal";
import {Button} from "@chakra-ui/react";

function MessageTable() {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleTemplateSubmit = (template) => {
        setIsModalOpen(false);
    }

    return (
        <div>
            <Button
                variant="blue"
                onClick={() => setIsModalOpen(true)}>צור הודעה חדשה</Button>
            <NewMessageModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} onSubmit={handleTemplateSubmit} />
        </div>
    );
}

export default MessageTable;
