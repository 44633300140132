export const ROLES = Object.freeze({
  SUPER_ADMIN: 'SuperAdmin',
  ADMIN: 'Admin',
  SUPER_ANALYTICS: 'SuperAnalytics',
  ANALYTICS: 'Analytics',
  FINANCE: 'Finance',
  EMPLOYEE: 'Employee',
});

export const globalScopedRoles = Object.freeze([ROLES.SUPER_ADMIN, ROLES.SUPER_ANALYTICS]);

export const rolesWithoutSuperRoles = [
  { label: ROLES.ADMIN, value: ROLES.ADMIN },
  { label: ROLES.ANALYTICS, value: ROLES.ANALYTICS },
  { label: ROLES.FINANCE, value: ROLES.FINANCE },
  { label: ROLES.EMPLOYEE, value: ROLES.EMPLOYEE },
];

export const rolesWithoutSuperAdmin = [
  ...rolesWithoutSuperRoles,
  { label: ROLES.SUPER_ANALYTICS, value: ROLES.SUPER_ANALYTICS },
];

export const fullRolesList = [
  ...rolesWithoutSuperRoles,
  { label: ROLES.SUPER_ADMIN, value: ROLES.SUPER_ADMIN },
  { label: ROLES.SUPER_ANALYTICS, value: ROLES.SUPER_ANALYTICS },
];
