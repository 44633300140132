import { Tabs, TabList, TabPanels, Tab, TabPanel, Box } from '@chakra-ui/react';
import CompaniesTable from './components/CompaniesTable';
import Organizations from '../organizations/Organizations';

export default function Companies() {
  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <Tabs align="start" variant="enclosed" isLazy lazyBehavior="unmount">
        <TabList>
          <Tab>חברות</Tab>
          <Tab>ארגונים</Tab>
        </TabList>
        <TabPanels>
          <TabPanel px={0}>
            <CompaniesTable />
          </TabPanel>
          <TabPanel px={0}>
            <Organizations />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}
