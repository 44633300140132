import styled from '@emotion/styled';
import { Box } from '@chakra-ui/react';

import { globalStyles } from '../../theme/styles';

export const StyledCalendarMenu = styled(Box)`
  ${({ selectedDates = { from: undefined, to: undefined } }) => {
    const selectedDatesAsStrings = {
      from: selectedDates.from ? selectedDates.from.toISOString() : undefined,
      to: selectedDates.to ? selectedDates.to.toISOString() : undefined,
    };

    return `
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 16px 12px;

      .rdp-root {
        --rdp-range_start-date-background-color: ${globalStyles.colors.brand[600]};
        --rdp-range_end-date-background-color: ${globalStyles.colors.brand[600]};
        --rdp-selected-border: 1px solid ${globalStyles.colors.brand[600]};
        --rdp-accent-background-color: ${globalStyles.colors.brand[500]}50;
        --rdp-today-color: ${globalStyles.colors.brand[600]};
        --rdp-accent-color: ${globalStyles.colors.brand[600]};
        --rdp-day-height: 36px;
        --rdp-day-width: 36px;
        --rdp-day_button-width: 34px;
        --rdp-day_button-height: 34px;
        --rdp-dropdown-gap: 16px;
      }

      .rdp-day_button {
        font-size: 14px;
      }

      .rdp-today .rdp-day_button {
        font-weight: bold;
        font-size: 16px;
      }

      .rdp-day_button {
        font-size: 14px;
      }

      .rdp-range_end .rdp-day_button {
        border-radius: ${selectedDatesAsStrings.from === selectedDatesAsStrings.to ? '50%' : '50% 0 0 50%'};
      }

      .rdp-range_start .rdp-day_button {
        border-radius: ${selectedDatesAsStrings.from === selectedDatesAsStrings.to ? '50%' : '0 50% 50% 0'};
      }

      .rdp-button_previous,
      .rdp-button_next {
        border-radius: 50%;
        :hover {
          background-color: #e2e8f0; //chakra-colors-gray-200
        }
      }

      .rdp-caption_label .rdp-chevron {
        margin-right: 4px;
      }
    `;
  }}
`;
