import {
    Avatar,
    Box,
    Flex,
    FormLabel,
    Icon,
    Select,
    SimpleGrid,
    useColorModeValue, useColorMode, Text
} from "@chakra-ui/react";
// Assets
// Custom components
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import React from "react";
import {
  MdFileCopy,
} from "react-icons/md";
import ComplexTable from "views/admin/default/components/ComplexTable";
import DailyLoginsRecentMonths from "./components/DailyLoginsRecentMonths";
import PieCard from "views/admin/default/components/PieCard";
import {
  columnsDataComplex,
} from "views/admin/default/variables/columnsData";
import { IoIosCalendar } from "react-icons/io";
import { BiTransfer } from "react-icons/bi"
import TotalTransfersRecentMonths from "./components/TotalTransferInFourMonth";
import { useEffect } from "react";
import AuthApi from "../../../api/auth"
import { useHistory } from "react-router-dom";
import AnalyticsApi from "../../../api/analytics";
import RecentlySignedUp from "./components/RecentlySignedUp";
import TopActiveEmployees from "./components/TopActiveEmployees";
import MonthlyTransfersChart from "../dataTables/components/MonthlyTransfersChart";

export default function UserReports() {
  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.600", "white");
  const boxBg = useColorModeValue("brand.100", "whiteAlpha.100");
  // const transfersToday = '₪3,504'
  // const transfersMonth = '₪64,239'
  // const transfersAvgMonth = '₪1,106.9'
    const history = useHistory();

    const [transfersToday, setTransfersToday] = React.useState('₪0')
    const [transfersMonth, setTransfersMonth] = React.useState('₪0')
    const [monthlyTransfersHistory, setMonthlyTransfersHistory] = React.useState([])
    const [monthlyLoginsHistory, setMonthlyLoginsHistory] = React.useState([])
    const [transfersAvgMonth, setTransfersAvgMonth] = React.useState('₪0')
    const [activeUsers, setActiveUsers] = React.useState(0)
    const [nonActiveUsers, setNonActiveUsers] = React.useState(0)
    const [transfersCount, setTransfersCount] = React.useState(0)
    const [usersTransferredCount, setUsersTransferredCount] = React.useState(0)

    const [transfersAvgMonthPreviousMonth, setTransfersAvgMonthPreviousMonth] = React.useState('₪0')
    const [transfersCountPreviousMonth, setTransfersCountPreviousMonth] = React.useState(0)
    const [usersTransferredCountPreviousMonth, setUsersTransferredCountPreviousMonth] = React.useState(0)


    const [recentTransfers, setRecentTransfers] = React.useState([])
    const { colorMode, toggleColorMode } = useColorMode()

    const getUserInfo = ()=> {
        let userInfo = JSON.parse(localStorage.getItem("user"));
        let accessToken = userInfo.token;

        AuthApi.GetUserInfo(accessToken).then(res => {
        })
       .catch(e => {
           if(e.response && e.response.status == 401) {
            }
        })
  }

    const getAnalytics = ()=> {
        AnalyticsApi.getAnalytics().then(res => {
            if(res.data) {
                // alert(JSON.stringify(res.data))
                let analytics = res.data;
                if(analytics.dailySum) {
                    setTransfersToday('₪'+Math.floor(analytics.dailySum))
                }
                if(analytics.monthlySum) {
                    setTransfersMonth('₪'+Math.floor(analytics.monthlySum))
                }
                if(analytics.monthlyTransfersHistory) {
                    setMonthlyTransfersHistory(analytics.monthlyTransfersHistory)
                }
                if(analytics.monthlyAmountAvg) {
                    setTransfersAvgMonth('₪'+Math.floor(analytics.monthlyAmountAvg))
                }
                if(analytics.activeUsers != null) {
                    setActiveUsers(analytics.activeUsers)
                }
                if(analytics.nonActiveUsers != null) {
                    setNonActiveUsers(analytics.nonActiveUsers+1)
                }
                if(analytics.recentTransfers != null) {
                    setRecentTransfers(analytics.recentTransfers)
                }

                if(analytics.transfersCount != null) {
                    setTransfersCount(analytics.transfersCount)
                }

                if(analytics.usersTransferredCount != null) {
                    setUsersTransferredCount(analytics.usersTransferredCount)
                }

                if(analytics.previousMonth) {
                    if(analytics.previousMonth.monthlyAmountAvg) {
                        setTransfersAvgMonthPreviousMonth('₪'+Math.floor(analytics.previousMonth.monthlyAmountAvg))
                    }
                    if(analytics.previousMonth.transfersCount) {
                        setTransfersCountPreviousMonth(analytics.previousMonth.transfersCount)
                    }
                    if(analytics.previousMonth.usersTransferredCount) {
                        setUsersTransferredCountPreviousMonth(analytics.previousMonth.usersTransferredCount)
                    }
                }

            }
        }).catch(e => {
        })
    }

    const getGoogleAnalytics = ()=> {
        AnalyticsApi.getGoogleAnalytics().then(res => {
            if(res.data.success) {
                let googleAnalytics = res.data.data;
                if(googleAnalytics.monthlyLoginsHistory) {
                    setMonthlyLoginsHistory(googleAnalytics.monthlyLoginsHistory)
                }
            }
        }).catch(e => {
        })
    }



    useEffect(() => {
        getAnalytics()
        getGoogleAnalytics()
        if (colorMode === 'dark') {
            toggleColorMode()
        }
        setTimeout(() => {
            getUserInfo()
        }, 1000);
    }, []);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>

        <SimpleGrid columns={{ base: 1, md: 2, xl: 5 }} gap='20px' mb='20px'>


                <MiniStatistics
                    startContent={
                        <IconBox
                            w='56px'
                            h='56px'
                            bg={boxBg}
                            icon={
                                <Icon w='32px' h='32px' as={BiTransfer} color={brandColor} />
                            }
                        />
                    }
                    name='העברות (היום)'
                    value={transfersToday}
                />
                <MiniStatistics
                    startContent={
                        <IconBox
                            w='56px'
                            h='56px'
                            bg={boxBg}
                            icon={
                                <Icon w='32px' h='32px' as={IoIosCalendar} color={brandColor} />
                            }
                        />
                    }
                    name='העברות (החודש)'
                    value={transfersMonth}
                />

                <MiniStatistics
                    startContent={
                        <IconBox
                            w='56px'
                            h='56px'
                            bg={boxBg}
                            icon={
                                <Icon w='32px' h='32px' as={IoIosCalendar} color={brandColor} />
                            }
                        />
                    }
                    name='העברה חודשית ממוצעת'
                    value={transfersAvgMonth}
                    valuePreviousMonth={transfersAvgMonthPreviousMonth}
                />

                <MiniStatistics
                    nameFontSize={"18px"}
                    nameColor={"secondaryGray.900"}
                    startContent={
                        <Flex
                            justifyContent='center'
                            align='center'
                            direction='column'
                            w='56px'
                            h='56px'
                            borderRadius='28px'
                            bg={boxBg}>
                            <Text
                                fontWeight='bold'
                                fontSize={'26px'}>
                                {usersTransferredCount}
                            </Text>
                        </Flex>
                    }
                    name='עובדים העבירו החודש'
                    valuePreviousMonth={usersTransferredCountPreviousMonth}
                />

                <MiniStatistics
                    nameFontSize={"18px"}
                    nameColor={"secondaryGray.900"}
                    startContent={
                        <Flex
                            justifyContent='center'
                            align='center'
                            direction='column'
                            w='56px'
                            h='56px'
                            borderRadius='28px'
                            bg={boxBg}>
                            <Text
                                fontWeight='bold'
                                fontSize={'26px'}>
                                {transfersCount}
                            </Text>
                        </Flex>
                    }
                    name='העברות החודש'
                    valuePreviousMonth={transfersCountPreviousMonth}
                />

            </SimpleGrid>

        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px' mb='20px'>

            <PieCard activeUsers={activeUsers}  nonActiveUsers={nonActiveUsers}/>

            <ComplexTable
                columnsData={columnsDataComplex}
                tableData={recentTransfers}
                pageSize={5} />

        </SimpleGrid>

        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px' mb='20px'>
            <TotalTransfersRecentMonths monthlySum={monthlyTransfersHistory} />
            <DailyLoginsRecentMonths history={monthlyLoginsHistory} />

      </SimpleGrid>

    </Box>
  );
}
