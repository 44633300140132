import { TagId, TagName, TagDate, TagActions } from '../../companies/components/companiesTableData';

export const TAGS = {
  ID: TagId,
  NAME: TagName,
  DATE: TagDate,
  ACTIONS: TagActions,
  COMPANIES: 9,
  STATUS: 10,
};

export const TABLE_COL_TYPES = {
  TEXT: 'text',
  LIST: 'list',
  BUTTON: 'button',
  BOOLEAN: 'boolean',
};

export const tableColumns = [
  {
    Header: 'מס׳ אירגון',
    accessor: 'id',
    tag: TagId,
    width: '10px',
    type: TABLE_COL_TYPES.TEXT,
  },
  {
    Header: 'אירגון',
    accessor: 'name',
    tag: TagName,
    width: '150px',
    type: TABLE_COL_TYPES.TEXT,
  },
  {
    Header: 'סטטוס',
    accessor: 'isActive',
    tag: TAGS.STATUS,
    width: '20px',
    type: TABLE_COL_TYPES.BOOLEAN,
  },
  {
    Header: 'חברות',
    accessor: 'companies',
    tag: TAGS.COMPANIES,
    width: '150px',
    type: TABLE_COL_TYPES.LIST,
  },
  {
    Header: 'פעולות נוספות',
    accessor: 'actions',
    tag: TagActions,
    width: '80px',
    type: TABLE_COL_TYPES.BUTTON,
  },
];
