import React, {useEffect, useMemo, useState} from "react";
import {useGlobalFilter, usePagination, useSortBy, useTable} from "react-table";
import {
    Button,
    Flex,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    useDisclosure,
    Select,
    Checkbox,
    Stack,
    Box,
} from "@chakra-ui/react";
import Card from "../../../../components/card/Card";
import {
    tableColumns,
    TagActions,
    TagAmount, TagCount,
    TagDate,
    TagName, TagStatus
} from "./reportsTableData";

import { prettifyPayPeriodDate} from "../../../../functions/dateUtils";
import AdminReportDetailsModal from "./AdminReportDetailsModal";
import AdminNewReportModal from "./AdminNewReportModal";
import ReportsApi, {Reports, ReportState} from "../../../../api/reports";
import MonthDropdownSelection from "../../../../functions/MonthDropdownSelection";
import AdminPaymentUpdateModal from "./AdminPaymentUpdateModal";

export default function AdminReportsTable() {
    const columns = useMemo(() => tableColumns, [tableColumns]);
    const [data, setData] = React.useState([]);
    const [filteredData, setFilteredData] = React.useState([]);
    const [date, setDate] = React.useState("01/10/2022");
    const [selectedReport, setSelectedReport] = React.useState(undefined);
    const [statusFilters, setStatusFilters] = useState({
        [ReportState.Created]: false,
        [ReportState.AdminApproved]: false,
        [ReportState.DocumentsSent]: false,
        [ReportState.SemiPaid]: false,
        [ReportState.FullyPaid]: false,
    });

    const refreshApplications = () => {
        ReportsApi.getReports(date).then(res => {
            setData(res.data);
            applyFilters(res.data, statusFilters);
        })
            .catch(e => {
                setData([]);
                setFilteredData([]);
            });
    };

    useEffect(() => {
        //refreshApplications()
    }, []);

    // Apply status filters to the data
    const applyFilters = (dataToFilter, filters) => {
        // Check if any filter is active
        const anyFilterActive = Object.values(filters).some(value => value);

        if (!anyFilterActive) {
            // If no filters are active, show all data
            setFilteredData(dataToFilter);
            return;
        }

        // Filter data based on selected statuses
        const filtered = dataToFilter.filter(item =>
            filters[item.state] === true
        );

        setFilteredData(filtered);
    };

    // Handle status filter change
    const handleStatusFilterChange = (status) => {
        const newFilters = {
            ...statusFilters,
            [status]: !statusFilters[status]
        };

        setStatusFilters(newFilters);
        applyFilters(data, newFilters);
    };

    // Use filtered data for the table
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        useTable({ columns, data: filteredData }, useSortBy);

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

    const {
        isOpen: isNewReportModalOpen,
        onOpen: onNewReportModalOpen,
        onClose: onNewReportModalClose
    } = useDisclosure();

    const {
        isOpen: isReportModalOpen,
        onOpen: onReportModalOpen,
        onClose: onReportModalClose
    } = useDisclosure();

    const {
        isOpen: isAdminPaymentUpdateModalOpen,
        onOpen: onAdminPaymentUpdateModalOpen,
        onClose: onAdminPaymentUpdateModalClose
    } = useDisclosure();

    const handleRefreshClick = () => {
        refreshApplications();
    };

    const handleDateChange = (event) => {
        setDate(event);
    };

    const handleEditClick = (cellRow) => {
        setSelectedReport(filteredData[cellRow.index]);
        onReportModalOpen();
    };

    const handleNewReportClick = () => {
        onNewReportModalOpen();
    };

    // Status filter section component
    const StatusFilterSection = () => (
        <Box mb='15px' p='15px' borderWidth='1px' borderRadius='md' borderColor={borderColor}>
            <Text mb='10px' fontWeight='bold'>סינון לפי סטטוס:</Text>
            <Stack direction='row' spacing={5}>
                <Checkbox
                    isChecked={statusFilters[ReportState.Created]}
                    onChange={() => handleStatusFilterChange(ReportState.Created)}
                >
                    נוצר
                </Checkbox>
                <Checkbox
                    isChecked={statusFilters[ReportState.AdminApproved]}
                    onChange={() => handleStatusFilterChange(ReportState.AdminApproved)}
                >
                    אושר
                </Checkbox>
                <Checkbox
                    isChecked={statusFilters[ReportState.DocumentsSent]}
                    onChange={() => handleStatusFilterChange(ReportState.DocumentsSent)}
                >
                    מסמכים נשלחו
                </Checkbox>
                <Checkbox
                    isChecked={statusFilters[ReportState.SemiPaid]}
                    onChange={() => handleStatusFilterChange(ReportState.SemiPaid)}
                >
                    שולם חלקי
                </Checkbox>
                <Checkbox
                    isChecked={statusFilters[ReportState.FullyPaid]}
                    onChange={() => handleStatusFilterChange(ReportState.FullyPaid)}
                >
                    שולם
                </Checkbox>
            </Stack>
        </Box>
    );

    return (
        <Card
            direction='column'
            w='100%'
            px='0px'
            overflowX={{ sm: "scroll", lg: "hidden" }}>
            <Flex px='25px' mb='20px' align='center'>

                <AdminPaymentUpdateModal isOpen={isAdminPaymentUpdateModalOpen} onClose={onAdminPaymentUpdateModalClose} />

                <MonthDropdownSelection handleSelectedMonth={handleDateChange}/>

                <AdminReportDetailsModal date={date} report={selectedReport} isOpen={isReportModalOpen} onClose={onReportModalClose} />

                <AdminNewReportModal isOpen={isNewReportModalOpen} onClose={onNewReportModalClose} />

                <Button marginRight='30px' width='80px' variant={'brand'} onClick={handleRefreshClick} >הצג</Button>

                <Button marginRight='30px' width='80px' variant={'brand'} onClick={handleNewReportClick}>חדש</Button>

                <Button marginRight='30px' variant={'brand'} onClick={onAdminPaymentUpdateModalOpen}>עדכון קובץ מס״ב</Button>

            </Flex>

            {/* Add the status filter section here */}
            <Box px='25px'>
                <StatusFilterSection />
            </Box>

            <Table {...getTableProps()} variant='simple' color='gray.500' mb='24px'>
                <Thead>
                    {headerGroups.map((headerGroup, index) => (
                        <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                            {headerGroup.headers.map((column, index) => (
                                <Th
                                    {...column.getHeaderProps(column.getSortByToggleProps())}
                                    pe='10px'
                                    key={index}
                                    borderColor={borderColor}>
                                    <Flex
                                        justify='space-between'
                                        align='center'
                                        fontSize={{ sm: "10px", lg: "12px" }}
                                        color='gray.400'>
                                        {column.render("Header")}
                                    </Flex>
                                </Th>
                            ))}
                        </Tr>
                    ))}
                </Thead>
                <Tbody {...getTableBodyProps()}>
                    {rows.map((row, index) => {
                        prepareRow(row);
                        return (
                            <Tr {...row.getRowProps()} key={index}>
                                {row.cells.map((cell, index) => {
                                    let data = "";
                                    let cellWidth = cell.column.width;
                                    if(cell.column.tag === TagAmount) {
                                        data = (
                                            <Text color={textColor} fontSize='sm' fontWeight='700'>
                                                ₪{cell.value}
                                            </Text>
                                        );
                                    }
                                    else if (cell.column.tag === TagName || cell.column.tag === TagCount) {
                                        data = (
                                            <Text color={textColor} fontSize='sm' fontWeight='700'>
                                                {cell.value}
                                            </Text>
                                        );
                                    }
                                    else if(cell.column.tag === TagDate) {
                                        data = (
                                            <Text color={textColor} fontSize='sm' fontWeight='700'>
                                                {prettifyPayPeriodDate(cell.value)}
                                            </Text>
                                        );
                                    }
                                    else if(cell.column.tag === TagStatus) {
                                        data = (
                                            <Text color={textColor} fontSize='sm' fontWeight='700'>
                                                {Reports.stateText(cell.value)}
                                            </Text>
                                        );
                                    }
                                    else if (cell.column.tag === TagActions) {
                                        data = (
                                            <Flex align='center'>
                                                <Button onClick={() => handleEditClick(cell.row)} marginLeft='20px' textColor='black' backgroundColor='green.200' height='35px'>פרטים</Button>
                                            </Flex>
                                        );
                                    }
                                    return (
                                        <Td
                                            {...cell.getCellProps()}
                                            key={index}
                                            fontSize={{ sm: "14px" }}
                                            width={cellWidth}
                                            borderColor='transparent'>
                                            {data}
                                        </Td>
                                    );
                                })}
                            </Tr>
                        );
                    })}
                </Tbody>
            </Table>
        </Card>
    );
}
