import { useEffect } from 'react';
import { useSortBy, useTable, usePagination } from 'react-table';
import {
  Button,
  Flex,
  Icon,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Tooltip,
  Select,
  IconButton,
} from '@chakra-ui/react';
import { MdCheckCircle, MdOutlineError } from 'react-icons/md';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';

import PipelineModal from './PipelineModal';
import TableSkeleton from './TableSkeleton';
import { formatDateWithDateFns } from '../../../../../functions/dateUtils';
import { usePipelineResults } from '../context/PipelineResultsContext';
import { TagDate, TagStatus, tableColumns, resultsPerPageOptions } from '../consts/tableConsts';

export default function PipelinesTable() {
  const {
    integrationData,
    resultsPerPage,
    updateResultsPerPage,
    pageIndex: contextPageIndex,
    updatePageIndex,
    totalPages,
    selectedPipeline,
    selectPipeline,
    dataLoading,
  } = usePipelineResults();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    setPageSize: tableSetPageSize,
    state: { pageIndex },
  } = useTable(
    {
      columns: tableColumns,
      data: integrationData,
      initialState: {
        pageIndex: contextPageIndex,
        pageSize: resultsPerPage,
      },
      manualPagination: true, // Tell the table we're handling pagination server-side
      pageCount: totalPages,
    },
    useSortBy,
    usePagination,
  );

  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const textColor = useColorModeValue('secondaryGray.900', 'white');

  const canPreviousPage = contextPageIndex > 0;
  const canNextPage = contextPageIndex < totalPages - 1;

  // Handle server-side page changes
  const handlePageChange = (newPageIndex) => {
    if (newPageIndex >= 0 && newPageIndex < totalPages) {
      updatePageIndex(newPageIndex);
    }
  };

  // When page size changes, update both table and context
  const handlePageSizeChange = (newSize) => {
    tableSetPageSize(newSize);
    updateResultsPerPage(newSize);
  };

  const handlePipelineDetailsClick = (cellRow) => {
    selectPipeline(cellRow.original);
  };

  const nextPage = () => {
    if (canNextPage) {
      handlePageChange(contextPageIndex + 1);
    }
  };

  const previousPage = () => {
    if (canPreviousPage) {
      handlePageChange(contextPageIndex - 1);
    }
  };

  // Sync table pageIndex with context
  useEffect(() => {
    if (pageIndex !== contextPageIndex) {
      gotoPage(contextPageIndex);
    }
  }, [contextPageIndex, gotoPage, pageIndex]);

  return (
    <>
      {selectedPipeline && <PipelineModal />}

      {dataLoading ? (
        <TableSkeleton rows={resultsPerPage} />
      ) : (
        <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
          <Thead>
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    pe="10px"
                    key={index}
                    borderColor={borderColor}
                  >
                    <Flex
                      justify="space-between"
                      align="center"
                      fontSize={{ sm: '10px', lg: '12px' }}
                      color="gray.400"
                    >
                      {column.render('Header')}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, index) => {
                    let data = '';
                    if (cell.column.tag === TagDate) {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="700">
                          {formatDateWithDateFns(cell?.value, 'dd/MM/yy HH:mm:ss')}
                        </Text>
                      );
                    } else if (cell.column.tag === TagStatus) {
                      data = (
                        <Flex justifyContent="space-between">
                          <Flex align="center">
                            <Icon
                              w="24px"
                              h="24px"
                              me="5px"
                              color={cell.value === 'success' ? 'green.500' : 'red.500'}
                              as={cell.value === 'success' ? MdCheckCircle : MdOutlineError}
                            />
                            <Text color={textColor} fontSize="sm" fontWeight="700">
                              {cell.value}
                            </Text>
                          </Flex>
                          <Button
                            onClick={() => handlePipelineDetailsClick(cell.row)}
                            marginRight="20px"
                            textColor="black"
                            backgroundColor="gray.200"
                            height="30px"
                          >
                            Info
                          </Button>
                        </Flex>
                      );
                    } else {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="700">
                          {cell.value}
                        </Text>
                      );
                    }
                    return (
                      <Td
                        {...cell.getCellProps()}
                        key={index}
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        borderColor="transparent"
                      >
                        {data}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      )}

      <Flex justifyContent="space-between" m={30} alignItems="center">
        <Flex alignItems="center">
          <Text flexShrink="0">
            עמוד
            <Text fontWeight="bold" as="span" paddingInline={1}>
              {contextPageIndex + 1}
            </Text>
            מתוך
            <Text fontWeight="bold" as="span" paddingRight={1}>
              {totalPages}
            </Text>
          </Text>

          <Select
            backgroundColor="white"
            mr="20px"
            w={32}
            value={resultsPerPage}
            onChange={(e) => {
              handlePageSizeChange(Number(e.target.value));
            }}
            textAlign="left"
          >
            {resultsPerPageOptions.map((size) => (
              <option key={size} value={size}>
                הצג {size}
              </option>
            ))}
          </Select>
        </Flex>
        <Flex>
          <Tooltip label="עמוד קודם">
            <IconButton
              ml={3}
              onClick={previousPage}
              isDisabled={!canPreviousPage}
              icon={<ChevronRightIcon h={6} w={6} />}
            />
          </Tooltip>
          <Tooltip label="עמוד הבא">
            <IconButton
              onClick={nextPage}
              isDisabled={!canNextPage}
              icon={<ChevronLeftIcon h={6} w={6} />}
            />
          </Tooltip>
        </Flex>
      </Flex>
    </>
  );
}
