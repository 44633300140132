import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdBarChart,
  MdPerson,
  MdHome,
  MdExtension,
  MdLogout,
} from "react-icons/md";
import { HiOutlineChat } from "react-icons/hi";

import {
  IoIosBusiness
} from "react-icons/io"
import { RiOrganizationChart } from "react-icons/ri";

import {
  GoDatabase
} from "react-icons/go"

import {
  HiDocumentReport,
  HiOutlineUsers
} from "react-icons/hi"

import { FaWpforms } from "react-icons/fa";

import {
  BiTransfer
} from "react-icons/bi"

import UserReports from "./views/admin/default/main";
import Integrations from "./views/admin/integrations/Integrations";
import DataTables from "views/admin/dataTables/DataTables";
import Employees from "./views/company/employees/Employees";

// Auth Imports
import SignIn from "./views/auth/signIn/SignIn";
import OTP from "./views/auth/otp/OTP";
import Companies from "./views/admin/companies/Companies";
import Users from "./views/admin/users/Users";
import Messaging from "./views/admin/messaging/Messaging";
import Applications from "./views/admin/applications/Applications";
import AdminReports from "./views/admin/reports/AdminReports";
import Reports from "./views/company/reports/Reports";
import Transfers from "./views/company/transfers/Transfers";
import DataPipelines from "./views/admin/dataPipelines/DataPipelines";
import MockApi from "./api/mockApi";
import FormsTable from "./views/company/forms/FormsTable";
import User from "./api/user";
import {getUserIfExists} from "./auth/auth.context";

const routes = [
  {
    name: "ראשי",
    layout: "/rtl",
    path: "/rtl-default",
    icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
    component: UserReports,
    roles: [1, 2]
  },
  {
    name: "חברות",
    layout: "/rtl",
    path: "/companies",
    icon: <Icon as={IoIosBusiness} width='20px' height='20px' color='inherit' />,
    component: Companies,
    roles: [1]
  },
  {
    name: "עובדים",
    layout: "/rtl",
    path: "/users",
    icon: <Icon as={HiOutlineUsers} width='20px' height='20px' color='inherit' />,
    component: Users,
    roles: [1]
  },
  {
  name: "הודעות",
    layout: "/rtl",
    path: "/messaging",
    icon: <Icon as={HiOutlineChat} width='20px' height='20px' color='inherit' />,
    component: Messaging,
    roles: [1]
},
  {
    name: "עובדים",
    layout: "/rtl",
    path: "/employees",
    icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
    component: Employees,
    roles: [2]
  },
  {
    name: "העברות",
    layout: "/rtl",
    path: "/applications",
    icon: <Icon as={BiTransfer} width='20px' height='20px' color='inherit' />,
    component: Applications,
    roles: [1]
  },
  {
    name: "העברות",
    layout: "/rtl",
    path: "/transfers",
    icon: <Icon as={BiTransfer} width='20px' height='20px' color='inherit' />,
    component: Transfers,
    roles: [2]
  },
  {
    name: "טפסים",
    layout: "/rtl",
    path: "/forms",
    icon: <Icon as={FaWpforms} width='20px' height='20px' color='inherit' />,
    component: FormsTable,
    ...(getUserIfExists() && (User.GetInfo().companyExternalId === "pay" || User.GetInfo().companyExternalId === "dom") ? { roles: [1, 2] } : { roles: [0] }),
  },
  {
    name: "דוחות",
    layout: "/rtl",
    path: "/reports",
    icon: <Icon as={HiDocumentReport} width='20px' height='20px' color='inherit' />,
    component: AdminReports,
    roles: [1]
  },
  {
    name: "דוחות",
    layout: "/rtl",
    path: "/reports-1",
    icon: <Icon as={HiDocumentReport} width='20px' height='20px' color='inherit' />,
    component: Reports,
    roles: [2]
  },
  {
    name: "אינטגרציות",
    layout: "/rtl",
    path: "/integrations",
    icon: <Icon as={MdExtension} width='20px' height='20px' color='inherit' />,
    component: Integrations,
    ...(MockApi.IsActive ? { roles: [1,2] } : { roles: [0] }),
  },
  {
    name: "נתונים",
    layout: "/rtl",
    icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
    path: "/data-tables",
    component: DataTables,
    roles: [1]
  },
  {
    name: "Data Pipelines",
    layout: "/rtl",
    icon: <Icon as={GoDatabase} width='20px' height='20px' color='inherit' />,
    path: "/data-pipelines",
    component: DataPipelines,
    roles: [1]
  },
  {
    name: "Signin",
    layout: "/auth",
    path: "/sign-in",
    icon: (
      <Icon as={MdHome} width='16px' height='16px' color='inherit' />
    ),
    component: SignIn,
    hide: true,
    roles: [1, 2]
  },
  {
    name: "Otp",
    layout: "/auth",
    path: "/otp",
    icon: (
      <Icon as={MdHome} width='16px' height='16px' color='inherit' />
    ),
    component: OTP,
    hide: true,
    roles: [1, 2]
  }
];

export const Logout = [
  {
    name: "התנתק",
    layout: "/auth",
    path: "/sign-out",
    icon: (
      <Icon as={MdLogout} width='16px' height='16px' color='inherit' />
    ),
    component: SignIn,
  }
];
export default routes;
