import { format, parseISO } from 'date-fns';
import { he } from 'date-fns/locale';

export const matchDateFormatToBackend = (dateString) => {
  const [sYear, sMonth, sDay] = dateString.split('-');
  const dateObj = new Date(+sYear, +sMonth - 1, +sDay);
  const dateAsBackendFormat =
    ('0' + dateObj.getDate()).slice(-2) +
    '/' +
    ('0' + (dateObj.getMonth() + 1)).slice(-2) +
    '/' +
    dateObj.getFullYear();
  return dateAsBackendFormat;
};

export const prettifyDate = (date) => {
  const dateObj = new Date(date);
  return dateObj.toLocaleDateString('he-IL'); // + " " + dateObj.toLocaleTimeString("he-IL");
};

export const prettifyTransferDate = (date) => {
  // date is a string in the format of "2024-06-05T19:43:09.854Z"
  // format it to 5.6.2024 19:43 Wednesday
  const dateObj = new Date(date);
  return (
    dateObj.toLocaleDateString('he-IL') +
    ' ' +
    dateObj.toLocaleTimeString('he-IL') +
    ' ' +
    dateObj.toLocaleDateString('he-IL', { weekday: 'long' })
  );
};

export const prettifyDateToTime = (date) => {
  const dateObj = new Date(date);
  return dateObj.toLocaleString('he-IL'); // + " " + dateObj.toLocaleTimeString("he-IL");
};

export const prettifyPayPeriodDate = (date) => {
  const [sMonth, sYear] = date.split('/');
  const dateObj = new Date(+sYear, +sMonth, 0);
  const month = dateObj.toLocaleString('he-IL', { month: 'long', year: 'numeric' });
  return month;
};

export function getRecentMonths() {
  const currentDate = new Date();
  const currentMonthIndex = currentDate.getMonth() + 1;
  const months = [
    'ינואר',
    'פברואר',
    'מרץ',
    'אפריל',
    'מאי',
    'יוני',
    'יולי',
    'אוגוסט',
    'ספטמבר',
    'אוקטובר',
    'נובמבר',
    'דצמבר',
  ];
  const size = 4;

  const start = (currentMonthIndex - size + months.length) % months.length;
  const end = currentMonthIndex % months.length;
  if (start < end) {
    return months.slice(start, end);
  } else {
    return months.slice(start).concat(months.slice(0, end));
  }
}

export function GenerateRecentMonthsForDropdown(inputDate = new Date()) {
  const inputMonthIndex = inputDate.getMonth();
  const inputYear = inputDate.getFullYear();
  const months = [
    'ינואר',
    'פברואר',
    'מרץ',
    'אפריל',
    'מאי',
    'יוני',
    'יולי',
    'אוגוסט',
    'ספטמבר',
    'אוקטובר',
    'נובמבר',
    'דצמבר',
  ];
  const selectedMonths = [];

  for (let i = 5; i >= 0; i--) {
    let monthIndex = (inputMonthIndex - i + 12) % 12;
    let year = inputYear - (inputMonthIndex - i < 0 ? 1 : 0);
    const monthString = `${(monthIndex + 1).toString().padStart(2, '0')}/${year}`;
    selectedMonths.push({ value: monthString, label: `${months[monthIndex]} ${year}` });
  }

  return selectedMonths;
}

export function getEndDate(month, year) {
  const lastDay = new Date(year, month, 0).getDate();
  const twoDigitMonth = month.toString().padStart(2, '0');
  const endDate = `${lastDay}/${twoDigitMonth}/${year}`;
  return endDate;
}

export function getMonthFromString(dateString) {
  let parts = dateString.split('/');
  let month = parseInt(parts[0], 10); // Convert string to integer
  return month;
}

/**
 * Format a date using date-fns with Hebrew locale
 * @param {string|Date} date - The date to format
 * @param {string} formatPattern - date-fns format pattern (default: 'Pp') - displays date and time
 * @returns {string} Formatted date string
 */
export const formatDateWithDateFns = (date, formatPattern = 'Pp') => {
  if (!date) return 'N/A';

  try {
    const dateObj = date instanceof Date ? date : parseISO(date);
    return format(dateObj, formatPattern, { locale: he });
  } catch (error) {
    console.error('Error formatting date:', error);
    return 'N/A';
  }
};
