import { Box, SimpleGrid } from '@chakra-ui/react';
import PipelineResults from './pipelineResults/PipelineResults';
// import PipelinesErrorsTable from "./pipelineErrors/PipelinesErrorsTable";

export default function DataPipelines() {
  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <SimpleGrid mb="20px" columns={{ sm: 1, md: 1 }} spacing={{ base: '20px', xl: '20px' }}>
        <PipelineResults />
        {/* <PipelinesErrorsTable /> */}
      </SimpleGrid>
    </Box>
  );
}
