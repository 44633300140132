export const skeletonStyles = {
  components: {
    Skeleton: {
      defaultProps: {
        startColor: 'gray.200',
        endColor: 'gray.300',
      },
    },
  },
};
