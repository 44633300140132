import { Flex, Text, Box } from '@chakra-ui/react';

import { usePipelineResults } from '../context/PipelineResultsContext';
import { formatDateWithDateFns } from '../../../../../functions/dateUtils';

const FiltersAppliedInfoSection = () => {
  const { appliedFilters, companiesOptions } = usePipelineResults();

  return (
    <Box
      mb={4}
      paddingInline={4}
      bg="blue.50"
      borderRadius="md"
      border="1px solid"
      borderColor="blue.300"
      p={3}
      mx={4}
      width="880px"
      maxWidth="95%"
    >
      <Text fontWeight="bold" mb={2}>
        מסננים פעילים:
      </Text>

      <Flex gap={3} flexWrap="wrap">
        {appliedFilters?.dates && (appliedFilters?.dates?.from || appliedFilters?.dates?.to) && (
          <Flex p={2} alignItems="center" gap={1}>
            <Text fontWeight="medium" mr={1}>
              תאריכים:
            </Text>
            <Text>
              {appliedFilters?.dates?.from
                ? formatDateWithDateFns(appliedFilters?.dates?.from, 'dd/MM/yy')
                : ''}
              -{' '}
              {appliedFilters?.dates?.to
                ? formatDateWithDateFns(appliedFilters?.dates?.to, 'dd/MM/yy')
                : ''}
            </Text>
          </Flex>
        )}

        {appliedFilters?.company && (
          <Flex p={2} alignItems="center" gap={1}>
            <Text fontWeight="medium" mr={1}>
              חברה:
            </Text>
            <Text>
              {companiesOptions.find((company) => company.value === appliedFilters?.company)
                ?.label || ''}
            </Text>
          </Flex>
        )}

        {appliedFilters?.status && (
          <Flex p={2} alignItems="center" gap={1}>
            <Text fontWeight="medium" mr={1}>
              סטטוס:
            </Text>
            <Text> {appliedFilters?.status}</Text>
          </Flex>
        )}
      </Flex>
    </Box>
  );
};

export default FiltersAppliedInfoSection;
