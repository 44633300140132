import React, {useEffect, useMemo} from "react";
import {useSortBy, useTable} from "react-table";
import {
    Button,
    Flex,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    useDisclosure,
    Select,
} from "@chakra-ui/react";
import Card from "../../../../components/card/Card";
import ApplicationsApi, {Applications} from "../../../../api/applications";
import{
    tableColumns,
    TagActions,
    TagAmount,
    TagDate,
    TagGovId,
    TagName,
    TagStatus,
    statusOptions,
} from "./applicationsTableData";
import {getEndDate, prettifyTransferDate} from "../../../../functions/dateUtils";
import GenerateTransfersModal from "./GenerateTransfersModal";
import EditApplicationModal from "./EditApplicationModal";
import User from '../../../../api/user'
import CompanyDropdownSelection from "../../../../functions/CompanyDropdownSelection";
import MonthDropdownSelection from "../../../../functions/MonthDropdownSelection";
import {exportArrayToXlsxFile} from "../../../../functions/generatePayrollFile";

export default function AdminTransfersTable() {
    let columns = useMemo(() => tableColumns, [tableColumns]);
    const [data, setData] = React.useState([])
    const [startDate, setStartDate] = React.useState("")
    const [endDate, setEndDate] = React.useState("")
    const [applicationStatus, setApplicationStatus] = React.useState("")
    const [selectedApplication, setSelectedApplication] = React.useState(undefined)
    const [userType, setUserType] = React.useState(-1)
    const [selectedCompanyName, setSelectedCompanyName] = React.useState("")


    const refreshApplications = ()=> {
        ApplicationsApi.getAll(applicationStatus, startDate, endDate).then(res => {
            if(res && res.data) {
                try {
                    res.data.sort(function(a, b) {
                        return new Date(b['createdAt']) - new Date(a['createdAt']) ;
                    });
                } catch (e) {

                }
            }
            if(selectedCompanyName === 'הכל' || selectedCompanyName === "") {
                setData(res.data)
            } else {
                setData(res.data.filter((row) => {
                    return row.companyName === selectedCompanyName;
                }))
            }
        })
        .catch(e => {
            setData([])
        })
    }

    useEffect(() => {
        setUserType(User.GetInfo().type)
        refreshApplications()
    }, []);

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data }, useSortBy);
    const filteredDataByCompany = (name, id) =>{
        setSelectedCompanyName(name)
    }

const textColor = useColorModeValue("secondaryGray.900", "white");
const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

const {
    isOpen: isExportModalOpen,
    onOpen: onExportModalOpen,
    onClose: onExportModalClose
} = useDisclosure()

const {
    isOpen: isEditModalOpen,
    onOpen: onEditModalOpen,
    onClose: onEditModalClose
} = useDisclosure()

const handleRefreshClick = (event) => {
    refreshApplications()
}

const handleExportApplicationsClick = () => {
    onExportModalOpen()
}

    const handleExportToExcelClick = () => {
        // filter data to only have certain fields
        const filteredData = data.map((row) => {
            return {
                "companyName": row.companyName,
                "fullName": row.fullName,
                "amount": row.amount,
                "createdAt": row.createdAt}
        });

        exportArrayToXlsxFile(filteredData);
    }

const handleStatusChange = (event) => {
    const value = event.target.value;
    setApplicationStatus(value)
}

const handleEditClick = (cellRow) => {
    setSelectedApplication(data[cellRow.index])
    onEditModalOpen()
}

const setStartAndEndDate = (selectedMonth) =>{
    const [month, year] = selectedMonth.split('/');
    const startDate = `01/${month}/${year}`;
    const endDate = getEndDate(month, year);
    setStartDate(startDate);
    setEndDate(endDate);
}

return (
    <Card
        direction='column'
        w='100%'
        px='0px'
        overflowX={{ sm: "scroll", lg: "hidden" }}>
        <Flex px='25px' mb='20px' align='center'>

            <MonthDropdownSelection handleSelectedMonth={setStartAndEndDate}/>

            <GenerateTransfersModal applications={data} isOpen={isExportModalOpen} onClose={onExportModalClose} />

            <EditApplicationModal application={selectedApplication} isOpen={isEditModalOpen} onClose={onEditModalClose} />

            <Select marginRight='30px' width='190px' pr='15px'  onChange={handleStatusChange}>
                <option value='88' disabled hidden>
                    בחר סטטוס העברה
                </option>
                {statusOptions.map((option) => (
                    <option key={option.val} value={option.val}>
                        {option.label}
                    </option>
                ))}
            </Select>

            <CompanyDropdownSelection selectedCompany={filteredDataByCompany} showAll={true}/>

            <Button
                marginRight='30px'
                width='80px'
                variant="blue"
                onClick={handleRefreshClick} >הצג
            </Button>

        </Flex>
        <Flex px='0px' mb='20px' align='center'>

            <Button
                marginRight='30px'
                width='150px'
                variant="blue"
                visibility={userType === 1 ? "visible" : "hidden"}
                onClick={handleExportApplicationsClick}>קובץ העברות לבנק</Button>

            <Button
                marginRight='30px'
                width='150px'
                variant="blue"
                visibility={userType === 1 ? "visible" : "hidden"}
                onClick={handleExportToExcelClick}>ייצוא לאקסל</Button>
        </Flex>


        <Table {...getTableProps()} variant='simple' color='gray.500' mb='24px'>
            <Thead>
                {headerGroups.map((headerGroup, index) => (
                    <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                        {headerGroup.headers.map((column, index) => (
                            <Th
                                {...column.getHeaderProps(column.getSortByToggleProps())}
                                pe='10px'
                                key={index}
                                borderColor={borderColor}>
                                <Flex
                                    justify='space-between'
                                    align='center'
                                    fontSize={{ sm: "10px", lg: "12px" }}
                                    color='gray.400'>
                                    {column.render("Header")}
                                </Flex>
                            </Th>
                        ))}
                    </Tr>
                ))}
            </Thead>

            <Tbody {...getTableBodyProps()}>
                {rows.map((row, index) => {
                    prepareRow(row);
                    return (
                        <Tr {...row.getRowProps()} key={index}>
                            {row.cells.map((cell, index) => {
                                let data = "";
                                let cellWidth = cell.column.width;
                                if(cell.column.tag === TagAmount) {
                                    data = (
                                        <Text color={textColor} fontSize='sm' fontWeight='700'>
                                            ₪{cell.value}
                                        </Text>
                                    );
                                }
                                else if (cell.column.tag === TagName ||
                                    cell.column.tag === TagGovId) {
                                    data = (
                                        <Text color={textColor} fontSize='sm' fontWeight='700'>
                                            {cell.value}
                                        </Text>
                                    );
                                }
                                else if(cell.column.tag === TagStatus) {
                                    data = (
                                        <Text color={textColor} fontSize='sm' fontWeight='700'>
                                            {Applications.statusText(cell.value)}
                                        </Text>
                                    );
                                }
                                else if(cell.column.tag === TagDate) {
                                    data = (
                                        <Text color={textColor} fontSize='sm' fontWeight='700'>
                                            {prettifyTransferDate(cell.value)}
                                        </Text>
                                    );
                                }
                                else if (cell.column.tag === TagActions) {
                                    data = (
                                        <Flex align='center'>
                                            <Button onClick={() => handleEditClick(cell.row)} marginLeft='20px' textColor='black' backgroundColor='green.200' height='35px'>פרטים</Button>
                                        </Flex>
                                    );
                                }
                                return (
                                    <Td
                                        {...cell.getCellProps()}
                                        key={index}
                                        fontSize={{ sm: "14px" }}
                                        width={cellWidth}
                                        borderColor='transparent'>
                                        {data}
                                    </Td>
                                );
                            })}
                        </Tr>
                    );
                })}
            </Tbody>
        </Table>
    </Card>
);
}
