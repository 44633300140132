import { Skeleton, Flex } from '@chakra-ui/react';

const UserAccessSkeleton = ({ rows = 3 }) => {
  return (
    <Flex direction="column" gap="32px" mt="40px" mb="40px">
      {Array.from({ length: rows }).map((_, index) => (
        <Skeleton key={index} height="40px" />
      ))}
    </Flex>
  );
};

export default UserAccessSkeleton;
