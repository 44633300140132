import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    SimpleGrid,
    useToast,
    Input,
    HStack,
    Text,
    Box,
} from "@chakra-ui/react";

import React from "react";
import ReportsApi from "../../../../api/reports";
import MonthDropdownSelection from "../../../../functions/MonthDropdownSelection";

export default function AdminNewReportModal(props) {

    let { isOpen, onClose, report } = props;
    const toast = useToast()
    const [reportStatus, setReportStatus] = React.useState("")
    const [companyId, setCompanyId] = React.useState("")
    const [payPeriod, setPayPeriod] = React.useState("")
    const [isLoading, setIsLoading] = React.useState(false)

    const handleStatusChange = (event) => {
        const value = event.target.value;
        setReportStatus(value)
    }

    const createReportHandleClick = () => {
        setIsLoading(true);
        ReportsApi.createEndOfMonthReport(payPeriod, companyId).then(res => {
            toast({
                title: `הדו״ח נוצר בהצלחה`,
                position: 'top',
                status: 'success',
                isClosable: false,
            })
            setIsLoading(false);
            onClose()
        }).catch(e => {
            toast({
                title: `שגיאה ביצירת הדו״ח`,
                position: 'top',
                status: 'error',
                isClosable: false,
            })
            setIsLoading(false);
        })
    }

    const createReportForAllCompaniesHandleClick = () => {
        setIsLoading(true);
        // Creating a report for all companies by passing the payPeriod and setting allCompanies flag to true
        ReportsApi.createEndOfMonthReportForAllCompanies(payPeriod).then(res => {
            toast({
                title: `הדו״חות נוצרו בהצלחה לכל החברות`,
                position: 'top',
                status: 'success',
                isClosable: false,
            })
            setIsLoading(false);
            onClose()
        }).catch(e => {
            toast({
                title: `שגיאה ביצירת הדו״חות`,
                position: 'top',
                status: 'error',
                isClosable: false,
            })
            setIsLoading(false);
        })
    }

    const handleClose = () => {
        if (!isLoading) {
            onClose();
        }
    }

    return (
        <Modal isOpen={isOpen}
               onClose={handleClose}
               isCentered
               closeOnOverlayClick={false}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>יצירת דו״ח חדש</ModalHeader>
                <ModalBody>

                    <SimpleGrid
                        marginTop='30px'
                        columns={{sm: 2}}
                        gap='20px'
                        mb='0px'>

                        <MonthDropdownSelection handleSelectedMonth={setPayPeriod}/>

                        <Input placeholder='מס׳ חברה' onChange={(event) => {
                            setCompanyId(event.target.value);
                        }} />

                    </SimpleGrid>

                    <Box mt="20px">
                        <Text fontSize="sm" color="gray.600">
                            בחר תקופת תשלום ומס' חברה כדי ליצור דו"ח לחברה מסוימת, או לחץ על "צור דו"חות לכל החברות" כדי ליצור דו"חות עבור כל החברות.
                        </Text>
                    </Box>

                    <br/>

                </ModalBody>
                <ModalFooter>
                    <HStack spacing={4}>
                        <Button
                            colorScheme="blue"
                            onClick={createReportHandleClick}
                            isLoading={isLoading}
                            isDisabled={!payPeriod || !companyId}
                        >
                            צור דו"ח
                        </Button>
                        <Button
                            colorScheme="green"
                            onClick={createReportForAllCompaniesHandleClick}
                            isLoading={isLoading}
                            isDisabled={!payPeriod}
                        >
                            צור דו"חות לכל החברות
                        </Button>
                        <Button onClick={handleClose} isDisabled={isLoading}>סגור</Button>
                    </HStack>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
