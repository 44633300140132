import { useEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  SimpleGrid,
  Flex,
  Button,
  Text,
} from '@chakra-ui/react';

import IntegrationsApi from '../../../../../api/integrations';
import { exportErrorsFile } from '../../../../../functions/generatePayrollFile';
import { usePipelineResults } from '../context/PipelineResultsContext';

const PipelineModal = () => {
  const [pipelineInformation, setPipelineInformation] = useState(undefined);
  const [errorsList, setErrorsList] = useState([]);

  const {
    isModalOpen: isOpen,
    onModalClose: onClose,
    toast,
    selectedPipeline,
  } = usePipelineResults();

  useEffect(() => {
    if (isOpen && selectedPipeline) {
      setPipelineInformation(selectedPipeline);
      getPipelineErrorsList(selectedPipeline.id);
    } else {
      setPipelineInformation(undefined);
      setErrorsList([]);
    }
  }, [isOpen, selectedPipeline]);

  const handleDownloadFileClick = () => {
    IntegrationsApi.downloadIntegrationFile(pipelineInformation.id)
      .then((res) => {
        try {
          let fileContent;
          let fileExtension;
          let isJsonFile = res.data instanceof Array;
          if (isJsonFile) {
            fileContent = JSON.stringify(res.data);
            fileExtension = '.json';
            const blob = new Blob([fileContent], {
              type: 'application/octet-stream',
            });
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.href = url;
            a.download = 'file' + fileExtension;
            a.click();
          } else {
            fileContent = res.data;
            let a = document.createElement('a');
            a.href = 'data:application/octet-stream;base64,' + fileContent;
            a.download = 'file' + fileExtension;
            a.click();
          }
        } catch (e) {}
      })
      .catch((e) => {});
  };

  const handleRunAgainClick = () => {
    IntegrationsApi.triggerIntegrationAction(pipelineInformation.integrationActionId)
      .then((res) => {
        toast({
          title: `Data Pipeline Started`,
          position: 'top',
          status: 'success',
          isClosable: false,
        });
      })
      .catch((e) => {
        toast({
          title: `Data Pipeline Failure`,
          position: 'top',
          status: 'error',
          isClosable: false,
        });
      });
  };

  const handleDownloadErrorsFileClick = () => {
    exportErrorsFile(errorsList);
  };

  const getPipelineErrorsList = (pipelineId) => {
    IntegrationsApi.getIntegrationsErrors(pipelineId, null)
      .then((res) => {
        if (res.data != null) {
          setErrorsList(res.data);
        }
      })
      .catch((e) => {});
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent maxW="1000px" dir="ltr">
        <ModalHeader>Data Pipeline</ModalHeader>
        <ModalBody>
          <SimpleGrid marginTop="30px" columns={{ sm: 1 }} gap="20px" mb="0px">
            <Flex flexDirection="row">
              <Button backgroundColor="green.200" width="200px" onClick={handleRunAgainClick}>
                Run Pipeline
              </Button>

              <Button
                marginLeft="50px"
                backgroundColor="gray.200"
                width="280px"
                onClick={handleDownloadFileClick}
              >
                Download Integration Response
              </Button>

              <Button
                marginLeft="50px"
                backgroundColor="red.200"
                width="200px"
                onClick={handleDownloadErrorsFileClick}
              >
                Download Errors Report
              </Button>
            </Flex>

            <Flex flexDirection="column">
              {errorsList && errorsList.length > 0 && (
                <Text fontWeight="bold" fontSize="18px" textColor="red">
                  Errors
                </Text>
              )}

              {errorsList && errorsList.length > 0 && (
                <div
                  style={{
                    height: '400px',
                    overflow: 'scroll',
                    outline: '0',
                    border: '1px solid #FFFFFF50',
                  }}
                >
                  <Flex flexDirection="column" marginBottom="20px">
                    {'\n'}
                    {errorsList.map((value) => (
                      <h4
                        style={{
                          fontSize: '1em',
                          textAlign: 'right',
                          fontWeight: '600',
                          transition: '.2s all',
                        }}
                      >
                        <br />
                        <Text align="left">{JSON.stringify(value).replace(/\\/g, '')}</Text>
                      </h4>
                    ))}
                  </Flex>
                </div>
              )}
            </Flex>
          </SimpleGrid>

          <br />
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>Close</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PipelineModal;
