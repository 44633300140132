import { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  FormControl,
  FormLabel,
  Input,
  ModalFooter,
  SimpleGrid,
  Select,
  useToast,
  Flex,
} from '@chakra-ui/react';
import styled from '@emotion/styled';

import EmployeesApi from '../../../../api/employees';
import { citiesB } from '../../../../data/citiesData';
import { AutoCompleteSelector } from '../../../../functions/AutoCompleteSelector';
import { rolesWithoutSuperRoles } from '../data/roles';
import { BANKS_LIST } from '../data/banks';

export default function AddNewUserModal(props) {
  let { isOpen, onClose, callBack, ...rest } = props;

  const [error, setError] = useState(undefined);
  const [cityId, setCityId] = useState(undefined);
  const [employeeJson, setEmployeeJson] = useState({});

  const citiesData = useMemo(() => citiesB, []);

  const handleChangeFor = (event) => {
    const { name, value } = event.target;
    const { employee } = employeeJson;

    const newEmployee = {
      ...employee,
      [name]: value,
    };
    setEmployeeJson({ employee: newEmployee });
  };

  useEffect(() => {
    if (isOpen) {
      setEmployeeJson({ employee: {} });
      setError(undefined);
    }
  }, [isOpen]);

  const toast = useToast();

  const addNewEmployee = () => {
    let employeeInfo = employeeJson.employee;
    if (!employeeInfo.firstName) {
      setError('אנא הכנס שם פרטי');
    } else if (!employeeInfo.lastName) {
      setError('אנא הכנס שם משפחה');
    } else if (!employeeInfo.govId || employeeInfo.govId.length != 9) {
      setError('אנא הכנס תעודת זהות');
    } else if (!employeeInfo.phoneNumber || employeeInfo.phoneNumber.length != 10) {
      setError('אנא הכנס טלפון');
    } else if (!employeeInfo.gender) {
      setError('אנא בחר מגדר');
    } else if (!employeeInfo.employerId) {
      setError('אנא הכנס מספר מעסיק');
    } else if (!employeeInfo.role) {
      setError('אנא הכנס הרשאה');
    } else if (cityId.length == 0) {
      setError('אנא בחר עיר');
    } else {
      employeeInfo['cityId'] = cityId;
      EmployeesApi.addEmployee(employeeInfo)
        .then((res) => {
          callBack();
          onClose();

          // Show success toast
          toast({
            title: 'יצירת המשתמש בוצעה בהצלחה',
            status: 'success',
            duration: 5000,
            isClosable: true,
            position: 'top',
          });
        })
        .catch(function (error) {
          setError('שגיאה');

          // Show error toast
          toast({
            title: 'לא הצלחנו ליצור את המשתמש',
            description: 'אנא נסה שנית מאוחר יותר',
            status: 'error',
            duration: 5000,
            isClosable: true,
            position: 'top',
          });
        });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent maxW="600px" maxH="700px">
        <ModalHeader>הוספת עובד חדש</ModalHeader>
        <ModalBody>
          <StyledError>{error}</StyledError>
          <FormLabel fontWeight="bold">פרטים אישיים</FormLabel>
          <SimpleGrid columns={{ sm: 2 }} gap="16px" mb="16px" pt={4}>
            <FormControl>
              <Input placeholder="שם פרטי" name="firstName" onChange={handleChangeFor} />
            </FormControl>

            <FormControl>
              <Input placeholder="שם משפחה" name="lastName" onChange={handleChangeFor} />
            </FormControl>

            <FormControl>
              <Input placeholder="תעודת זהות" name="govId" onChange={handleChangeFor} />
            </FormControl>

            <FormControl>
              <Input placeholder="טלפון" name="phoneNumber" onChange={handleChangeFor} />
            </FormControl>
          </SimpleGrid>
          <SimpleGrid columns={{ lg: 3 }} gap="16px" mb="24px">
            <FormControl>
              <Input type="date" placeholder="תאריך לידה" name="dob" onChange={handleChangeFor} />
            </FormControl>

            <StyledSelect placeholder="בחר מגדר" name="gender" onChange={handleChangeFor}>
              <option value="male">גבר</option>
              <option value="female">אישה</option>
              <option value="other">אחר</option>
            </StyledSelect>

            <FormControl>
              <AutoCompleteSelector state={cityId} stateSetter={setCityId} data={citiesData} />
            </FormControl>
          </SimpleGrid>

          <FormLabel fontWeight="bold">פרטי המעסיק העיקרי</FormLabel>
          <SimpleGrid columns={{ lg: 2 }} gap="16px" mb="16px" pt={4}>
            <FormControl>
              <Input placeholder="מס מעסיק" name="employerId" onChange={handleChangeFor} />
            </FormControl>

            <StyledSelect placeholder="בחר הרשאה" name="role" onChange={handleChangeFor}>
              {rolesWithoutSuperRoles.map((role) => (
                <option value={role.value}>{role.label}</option>
              ))}
            </StyledSelect>
          </SimpleGrid>
          <SimpleGrid columns={{ lg: 3 }} gap="16px" mb="24px">
            <FormControl>
              <Input placeholder="מס עובד" name="internalId" onChange={handleChangeFor} />
            </FormControl>

            <FormControl>
              <Input placeholder="מחלקה" name="jobDepartment" onChange={handleChangeFor} />
            </FormControl>

            <FormControl>
              <Input placeholder="הגדרת תפקיד" name="jobTitle" onChange={handleChangeFor} />
            </FormControl>
          </SimpleGrid>
          <FormLabel fontWeight="bold">פרטי חשבון בנק</FormLabel>
          <SimpleGrid columns={{ lg: 3 }} gap="16px" mb="0px" pt={4}>
            <StyledSelect placeholder="בחר בנק" name="bankId" onChange={handleChangeFor}>
              {BANKS_LIST.map((bank) => (
                <option value={bank.value}>{bank.label}</option>
              ))}
            </StyledSelect>

            <FormControl>
              <Input placeholder="סניף" name="branchId" onChange={handleChangeFor} />
            </FormControl>

            <FormControl>
              <Input placeholder="חשבון" name="accountId" onChange={handleChangeFor} />
            </FormControl>
          </SimpleGrid>
        </ModalBody>
        <ModalFooter>
          <Flex gap="16px">
            <Button onClick={addNewEmployee}>אישור</Button>
            <Button onClick={onClose}>סגור</Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

// ----------------------------------- Styled Components -----------------------------------

const StyledError = styled.h4`
  font-size: 1.2em;
  color: red;
  text-align: center;
  font-weight: 400;
  transition: '.2s all';
`;

const StyledSelect = styled(Select)`
  padding-inline: 32px 16px;
`;
