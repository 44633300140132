export const BANKS = Object.freeze({
  HAPOALIM: '12',
  OTZAR_HAHAYAL: '14',
  BANK_HADOAR: '9',
  DISCOUNT: '11',
  LEUMI: '10',
  MIZRAHI_TEFAHOT: '20',
  ONE_ZERO: '18',
  INTERNATIONAL: '31',
  IGUD: '13',
  YAHAV: '04',
  JERUSALEM: '54',
  MASAD: '46',
  MERCANTILE_DISCOUNT: '17',
  ARAB_ISRAELI: '34',
  U_BANK: '26',
});

export const BANKS_LIST = [
  { label: 'הפועלים', value: BANKS.HAPOALIM },
  { label: 'אוצר החייל', value: BANKS.OTZAR_HAHAYAL },
  { label: 'בנק הדואר', value: BANKS.BANK_HADOAR },
  { label: 'דיסקונט', value: BANKS.DISCOUNT },
  { label: 'לאומי', value: BANKS.LEUMI },
  { label: 'מזרחי טפחות', value: BANKS.MIZRAHI_TEFAHOT },
  { label: 'וואן זירו', value: BANKS.ONE_ZERO },
  { label: 'הבינלאומי', value: BANKS.INTERNATIONAL },
  { label: 'אגוד', value: BANKS.IGUD },
  { label: 'יהב לעובדי מדינה', value: BANKS.YAHAV },
  { label: 'ירושלים', value: BANKS.JERUSALEM },
  { label: 'מסד', value: BANKS.MASAD },
  { label: 'מרכנתיל דיסקונט', value: BANKS.MERCANTILE_DISCOUNT },
  { label: 'ערבי ישראלי', value: BANKS.ARAB_ISRAELI },
  { label: 'יובנק', value: BANKS.U_BANK },
];
