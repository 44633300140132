import {API} from "./env";
import {axiosInstance} from "./httpClient";
import MockApi from "./mockApi";


export const ReportState = {
    Created: 100,
    AdminApproved: 200,
    DocumentsSent: 300,
    SemiPaid: 400,
    FullyPaid: 500,
}

export class Reports {
    // this should be in sync with backend status

    static stateText = (status) => {
        switch (status) {
            case ReportState.Created:
                return "נוצר"
            case ReportState.AdminApproved:
                return "אושר"
            case ReportState.DocumentsSent:
                return "מסמכים נשלחו"
            case ReportState.SemiPaid:
                return "שולם חלקי"
            case ReportState.FullyPaid:
                return "שולם"
            default:
                return "NONE"
        }
    }
}

export default class ReportsApi {



    static sendDocumentsByEmail = (selectedDocuments) => {
        let userInfo = JSON.parse(localStorage.getItem("user"));
        let body = JSON.stringify(selectedDocuments);
        let accessToken = userInfo.token;
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        };
        console.log("selectedDocuments is", selectedDocuments);
        return axiosInstance.post(`${API}/reports/send-documents-by-email`, body, config);
    }

    static createDocument = async (documentDto) => {
        const endpoint = 'create-document';

        let userInfo = JSON.parse(localStorage.getItem("user"));
        let accessToken = userInfo.token;
        const config = {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        };

        return axiosInstance.post(`${API}/${endpoint}`, documentDto, config)
    }

    static downloadDocumentPdf = async (selectedDocumentType, reportId) => {
        let userInfo = JSON.parse(localStorage.getItem("user"));
        let accessToken = userInfo.token;

        let body = {
            documentType: String(selectedDocumentType),
            reportId: String(reportId)
        };

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            },
            responseType: 'arraybuffer'
        };

        return axiosInstance.post(`${API}/reports/download-document`, JSON.stringify(body), config);
    }

    static getTransfersReport = () => {
        let userInfo = JSON.parse(localStorage.getItem("user"));
        let accessToken = userInfo.token;
        const config = {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        };

        return axiosInstance.get(`${API}/reports/transfers`, config);
    }

    static validateDailyTransfersFile = (file) => {
        let userInfo = JSON.parse(localStorage.getItem("user"));
        let accessToken = userInfo.token;
        let formData = new FormData();
        formData.append("transfers", file)
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + accessToken
            }
        };
        return axiosInstance.post(`${API}/reports/transfers/validate`, formData, config);
    }

    static approveTransfersToTransfer = (transfersIds, reference, activityReference) => {
        let userInfo = JSON.parse(localStorage.getItem("user"));
        let accessToken = userInfo.token;
        let body = {
            transfers: transfersIds,
            reference: reference,
            activityReference: activityReference
        };
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        };

        return axiosInstance.post(`${API}/reports/transfers/approve`, JSON.stringify(body), config);
    }

    static createEndOfMonthReport = (aPayPeriod, aCompanyId) => {
        let userInfo = JSON.parse(localStorage.getItem("user"));
        let accessToken = userInfo.token;
        let body = {
            payPeriod: aPayPeriod,
            companyId: aCompanyId
        };
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        };

        return axiosInstance.post(`${API}/reports`, JSON.stringify(body), config);
    }

    static createEndOfMonthReportForAllCompanies = (aPayPeriod) => {
        let userInfo = JSON.parse(localStorage.getItem("user"));
        let accessToken = userInfo.token;
        let body = {
            payPeriod: aPayPeriod,
            allCompanies: true
        };
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        };

        return axiosInstance.post(`${API}/reports`, JSON.stringify(body), config);
    }

    static getReports = (aPayPeriod) => {
        let userInfo = JSON.parse(localStorage.getItem("user"));
        let accessToken = userInfo.token;
        let body = {
            payPeriod: aPayPeriod
        };
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        };

        return axiosInstance.post(`${API}/reports/get`, JSON.stringify(body), config);
    }

    static getReportFullDetails = (reportId) => {
        let userInfo = JSON.parse(localStorage.getItem("user"));
        let accessToken = userInfo.token;
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        };

        return axiosInstance.get(`${API}/reports/` + reportId, config);
    }


    static updateReport = (reportId, notes, state) => {
        let userInfo = JSON.parse(localStorage.getItem("user"));
        let accessToken = userInfo.token;
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        };

        let body = {
            reportId: reportId
        };

        if (notes !== null && notes !== undefined) {
            body.notes = notes;
        }

        if (state !== null && state !== undefined) {
            body.state = state;
        }

        return axiosInstance.post(`${API}/reports/update/`, JSON.stringify(body), config);
    }

    static getFolders = () => {
        let userInfo = JSON.parse(localStorage.getItem("user"));
        let accessToken = userInfo.token;
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        };

        return axiosInstance.get(`${API}/crm/folders/`, config);
    }


    static getFolderItems = (folderId) => {
        let userInfo = JSON.parse(localStorage.getItem("user"));
        let accessToken = userInfo.token;
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        };

        return axiosInstance.get(`${API}/crm/folders/`+folderId, config);
    }


    static getTransfersListWithFee = (aPayPeriod, reportCompanyId) => {
        if(MockApi.IsActive) {
            const response = MockApi.getTransfersListWithFee();
            return response
        }
        let userInfo = JSON.parse(localStorage.getItem("user"));
        let accessToken = userInfo.token;
        let body = {
            payPeriod: aPayPeriod,
            companyId:reportCompanyId
        };
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        };
        return axiosInstance.post(`${API}/reports/transfersFeesReport`, JSON.stringify(body), config);
    }

    static downloadReport = (reportType, aPayPeriod, companyId) => {
        if(MockApi.IsActive) {
            const response = MockApi.downloadReport();
            return response
        }

        let userInfo = JSON.parse(localStorage.getItem("user"));
        let accessToken = userInfo.token;
        let body = {
            companyId: companyId ?? "",
            payPeriod: aPayPeriod,
            type: reportType
        };
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        };

        return axiosInstance.post(`${API}/reports/download`, JSON.stringify(body), config);
    }


    static approveReport = (aReportId = null, masavFileId = null, bankActivityReference = null) => {
        let userInfo = JSON.parse(localStorage.getItem("user"));
        let accessToken = userInfo.token;
        let body = {
        };

        if(aReportId !== null && aReportId !== undefined) {
            body.reportId = aReportId;
        }
        if(masavFileId !== null && masavFileId !== undefined) {
            body.masavFileId = masavFileId;
        }
        if(bankActivityReference !== null && bankActivityReference !== undefined) {
            body.bankActivityReference = bankActivityReference;
        }
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        };

        return axiosInstance.post(`${API}/reports/approve`, JSON.stringify(body), config);
    }


    static addDocumentToReport = (reportId, selectedDocumentId, selectedDocumentType) => {
        let userInfo = JSON.parse(localStorage.getItem("user"));
        let accessToken = userInfo.token;
        let body = {
            reportId: reportId,
            documentId: selectedDocumentId,
            documentType: selectedDocumentType
        };

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        };

        return axiosInstance.post(`${API}/reports/documents`, JSON.stringify(body), config);
    }

    static sendInvoiceEmailWithAttachment(body) {
        let userInfo = JSON.parse(localStorage.getItem("user"));
        let accessToken = userInfo.token;

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + accessToken
            }
        };

        return axiosInstance.post(`${API}/invoices/send-invoice-email`, body, config);
    }
}
