import { API } from './env';
import { axiosInstance } from './httpClient';

export default class OrganizationsApi {
  static getAuthHeaders() {
    const userInfo = JSON.parse(localStorage.getItem('user'));
    const accessToken = userInfo.token;
    return {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + accessToken,
    };
  }

  static getAll = () => {
    const config = {
      method: 'get',
      headers: this.getAuthHeaders(),
    };

    return axiosInstance.get(`${API}/organization/all`, config);
  };

  static create = (organization) => {
    const config = {
      method: 'post',
      headers: this.getAuthHeaders(),
    };

    return axiosInstance.post(`${API}/organization`, JSON.stringify(organization), config);
  };

  static update = (organization) => {
    const config = {
      method: 'put',
      headers: this.getAuthHeaders(),
    };

    return axiosInstance.put(
      `${API}/organization/${organization.id}`,
      JSON.stringify(organization),
      config,
    );
  };

  static delete = (organizationId) => {
    const config = {
      method: 'delete',
      headers: this.getAuthHeaders(),
    };
    return axiosInstance.delete(`${API}/organization/${organizationId}`, config);
  };
}
