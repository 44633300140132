import { useEffect, useMemo, useState, useRef } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  FormControl,
  FormLabel,
  Input,
  ModalFooter,
  SimpleGrid,
  Select,
  useToast,
  Flex,
  Text,
  Spinner,
  Textarea,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from '@chakra-ui/react';
import styled from '@emotion/styled';

import UserAccess from './UserAccess';
import EmployeesApi from '../../../../api/employees';
import { citiesB } from '../../../../data/citiesData';
import { prettifyMissingInformationStatus } from '../../../../functions/common';
import {
  generateEmployeeShiftsFile,
  generateEmployeeTransfersFile,
} from '../../../../functions/generateEmployeeReports';
import { AutoCompleteSelector } from '../../../../functions/AutoCompleteSelector';
import { BANKS_LIST } from '../data/banks';

export default function EditUserModal(props) {
  let { isOpen, onClose, employee, callBack, ...rest } = props;

  const citiesData = useMemo(() => citiesB, []);
  const toast = useToast();
  const [error, setError] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [govId, setGovId] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [dob, setDob] = useState('');
  const [gender, setGender] = useState('');
  const [status, setStatus] = useState('');
  const [cityId, setCityId] = useState(undefined);
  const [companyId, setCompanyId] = useState('');
  const [internalId, setInternalId] = useState('');
  const [integrationUid, setIntegrationUid] = useState('');
  const [jobDepartment, setJobDepartment] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [branch, setBranch] = useState('');
  const [bankId, setBankId] = useState('');
  const [accountId, setAccountId] = useState('');
  const [branchId, setBranchId] = useState('');
  const [bankRecipientName, setBankRecipientName] = useState('');

  const [shiftsCount, setShiftsCount] = useState('');
  const [shiftsHistory, setShiftsHistory] = useState([]);
  const [totalWorkingHours, setTotalWorkingHours] = useState('');
  const [hourlyGrossRate, setHourlyGrossRate] = useState('');
  const [availableToTransferFactor, setAvailableToTransferFactor] = useState('');
  const [monthlyGrossRate, setMonthlyGrossRate] = useState('');
  const [earnedPay, setEarnedPay] = useState('');
  const [totalTransferred, setTotalTransferred] = useState('');
  const [availableToTransfer, setAvailableToTransfer] = useState('');
  const [transfersHistory, setTransfersHistory] = useState([]);
  const [salaryChanged, setSalaryChanged] = useState(false);

  const [endDate, setEndDate] = useState(undefined);
  const [employeeTransferLimit, setEmployeeTransferLimit] = useState(undefined);
  const [notes, setNotes] = useState('');
  const [userRoleAccess, setUserRoleAccess] = useState(null);
  const [missingInformation, setMissingInformation] = useState([]);

  const modalContentRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      setLoading(true);
      setTimeout(() => {
        EmployeesApi.getEmployeeDetails(employee.companyId, employee.id)
          .then((res) => {
            const employee = res.data.details;
            const employeeDetails = res.data.details.details;
            const timeAttendance = res.data.timeAttendance;
            const salaryDetails = res.data.salaryDetails;
            const transfers = res.data.transfers;
            const accessData = res?.data?.details?.userRoleAccess;

            if (employee.firstName != null) {
              setFirstName(employee.firstName);
            }
            if (employee.lastName != null) {
              setLastName(employee.lastName);
            }
            if (employee.phoneNumber != null) {
              setPhoneNumber(employee.phoneNumber);
            }
            if (employee.status != null) {
              setStatus(employee.status);
            }
            if (employee.companyId != null) {
              setCompanyId(employee.companyId);
            }
            if (employee.informationStatus != null) {
              setMissingInformation(employee.informationStatus);
            }
            if (employeeDetails != null) {
              if (employeeDetails.govId != null) {
                setGovId(employeeDetails.govId);
              }
              if (employeeDetails.dob != null) {
                setDob(employeeDetails.dob);
              }
              if (employeeDetails.gender != null) {
                setGender(employeeDetails.gender);
              }
              if (accessData) {
                setUserRoleAccess(accessData);
              }

              if (employeeDetails.cityId != null) {
                setCityId(employeeDetails.cityId);
              }
              if (employeeDetails.internalEmployeeId != null) {
                setInternalId(employeeDetails.internalEmployeeId);
              }
              if (employeeDetails.integrationUid != null) {
                setIntegrationUid(employeeDetails.integrationUid);
              }
              if (employeeDetails.jobDepartment != null) {
                setJobDepartment(employeeDetails.jobDepartment);
              }
              if (employeeDetails.jobTitle != null) {
                setJobTitle(employeeDetails.jobTitle);
              }
              if (employeeDetails.branch) {
                setBranch(employeeDetails.branch);
              }
              if (employeeDetails.bankId != null) {
                setBankId(employeeDetails.bankId);
              }
              if (employeeDetails.bankAccountId != null) {
                setAccountId(employeeDetails.bankAccountId);
              }
              if (employeeDetails.bankBranchId != null) {
                setBranchId(employeeDetails.bankBranchId);
              }
              if (employeeDetails.bankRecipientName != null) {
                setBankRecipientName(employeeDetails.bankRecipientName);
              }
              if (employeeDetails.endDate != null) {
                setEndDate(employeeDetails.endDate);
              }
              setEmployeeTransferLimit(employeeDetails.monthlyTransfersLimit);

              if (employeeDetails.notes != null) {
                setNotes(employeeDetails.notes);
              }
            }
            if (timeAttendance != null) {
              if (timeAttendance.shifts != null) {
                setShiftsCount(timeAttendance.shifts.length);
                setShiftsHistory(timeAttendance.shifts);
              }
              if (timeAttendance.workingHours) {
                setTotalWorkingHours(timeAttendance.workingHours);
              }
            }
            if (salaryDetails != null) {
              if (salaryDetails.hourlyGrossRate != null) {
                setHourlyGrossRate(salaryDetails.hourlyGrossRate);
              }
              if (salaryDetails.monthlyGrossRate != null) {
                setMonthlyGrossRate(salaryDetails.monthlyGrossRate);
              }
              if (salaryDetails.earnedPay) {
                setEarnedPay(salaryDetails.earnedPay);
              }
              if (salaryDetails.availableToTransferFactor) {
                setAvailableToTransferFactor(salaryDetails.availableToTransferFactor);
              }
            }

            if (transfers !== null) {
              if (transfers.totalTransferred != null) {
                setTotalTransferred(transfers.totalTransferred);
              }
              if (transfers.availableToTransfer != null) {
                setAvailableToTransfer(transfers.availableToTransfer);
              }
              if (transfers.history != null) {
                setTransfersHistory(transfers.history);
              }
            }

            setLoading(false);
            if (modalContentRef.current) {
              modalContentRef.current.scrollTo({ top: 0, behavior: 'smooth' });
            }
          })
          .catch((e) => {});
      }, 200);
    } else {
      setLoading(false);
      setFirstName('');
      setLastName('');
      setGovId('');
      setPhoneNumber('');
      setDob('');
      setGender('');
      setStatus('');
      setCityId(undefined);
      setCompanyId('');
      setInternalId('');
      setIntegrationUid('');
      setJobDepartment('');
      setJobTitle('');
      setBranch('');
      setBankId('');
      setAccountId('');
      setBranchId('');
      setBankRecipientName('');
      setMissingInformation([]);
      setShiftsCount('');
      setShiftsHistory([]);
      setTotalWorkingHours('');
      setHourlyGrossRate('');
      setMonthlyGrossRate('');
      setAvailableToTransferFactor('');
      setEarnedPay('');
      setTotalTransferred('');
      setAvailableToTransfer('');
      setTransfersHistory([]);
      setSalaryChanged(false);
      setEmployeeTransferLimit('');
      setNotes('');
      setUserRoleAccess(null);
    }
  }, [isOpen]);

  const downloadEmployeeShiftsReport = () => {
    if (shiftsHistory === null || shiftsHistory.length === 0) {
      toast({
        title: `אין משמרות החודש`,
        position: 'top',
        status: 'error',
        isClosable: false,
      });
      return;
    }

    let employeeName;
    if (firstName != null) {
      employeeName = firstName;
    }
    if (lastName) {
      employeeName = employeeName + ' ' + lastName;
    }
    generateEmployeeShiftsFile(employeeName, shiftsHistory);
  };

  const downloadTransfersReport = () => {
    if (transfersHistory === null || transfersHistory.length === 0) {
      toast({
        title: `אין העברות החודש`,
        position: 'top',
        status: 'error',
        isClosable: false,
      });
      return;
    }
    let employeeName;
    if (firstName != null) {
      employeeName = firstName;
    }
    if (lastName) {
      employeeName = employeeName + ' ' + lastName;
    }
    generateEmployeeTransfersFile(employeeName, transfersHistory);
  };

  const updateEmployee = () => {
    setError(undefined);
    let employeeDetailsJson = {};
    let bankDetailsJson = {};
    let salaryDetailsJson = {};

    let employeeJson = {
      userId: employee.id,
    };
    if (firstName) {
      employeeJson['firstName'] = firstName;
    }
    if (lastName) {
      employeeJson['lastName'] = lastName;
    }
    if (phoneNumber) {
      employeeJson['phoneNumber'] = phoneNumber;
    }
    if (status != null && status.length > 0) {
      employeeJson['status'] = Number(status);
    }
    if (govId) {
      employeeDetailsJson['govId'] = govId;
    }
    if (dob) {
      employeeDetailsJson['dob'] = dob;
    }
    if (gender) {
      employeeDetailsJson['gender'] = gender;
    }

    if (cityId) {
      employeeDetailsJson['cityId'] = cityId;
    }
    if (companyId) {
      employeeDetailsJson['employerId'] = companyId;
    }
    if (internalId) {
      employeeDetailsJson['internalId'] = internalId;
    }

    if (integrationUid) {
      employeeDetailsJson['integrationId'] = integrationUid;
    }

    if (branch) {
      employeeDetailsJson['branch'] = branch;
    }
    if (jobTitle) {
      employeeDetailsJson['jobTitle'] = jobTitle;
    }
    if (jobDepartment) {
      employeeDetailsJson['jobDepartment'] = jobDepartment;
    }
    if (bankId) {
      bankDetailsJson['bankId'] = bankId;
    }
    if (branchId) {
      bankDetailsJson['branchId'] = branchId;
    }
    if (accountId) {
      bankDetailsJson['accountId'] = accountId;
    }
    if (bankRecipientName) {
      bankDetailsJson['bankRecipientName'] = bankRecipientName;
    }
    if (cityId) {
      employeeDetailsJson['cityId'] = cityId;
    }
    if (employeeTransferLimit) {
      employeeDetailsJson['transferLimit'] = Number(employeeTransferLimit);
    } else {
      employeeDetailsJson['transferLimit'] = employeeTransferLimit;
    }

    if (endDate) {
      employeeDetailsJson['endDate'] = endDate;
    }
    if (notes) {
      employeeDetailsJson['notes'] = notes;
    }

    if (salaryChanged) {
      if (hourlyGrossRate) {
        salaryDetailsJson['hourlyGrossRate'] = hourlyGrossRate;
      }
      if (monthlyGrossRate) {
        salaryDetailsJson['monthlyGrossRate'] = monthlyGrossRate;
      }
      if (availableToTransferFactor) {
        salaryDetailsJson['availableToTransferFactor'] = availableToTransferFactor;
      }
    }
    if (Object.keys(employeeDetailsJson).length > 0) {
      employeeJson['details'] = employeeDetailsJson;
    }
    if (Object.keys(bankDetailsJson).length > 0) {
      employeeJson['details']['bankAccountDetails'] = bankDetailsJson;
    }
    if (Object.keys(salaryDetailsJson).length > 0) {
      employeeJson['details']['salaryDetails'] = salaryDetailsJson;
    }
    EmployeesApi.update(employeeJson)
      .then((res) => {
        callBack();
        onClose();

        toast({
          title: `השינויים נשמרו בהצלחה`,
          position: 'top',
          status: 'success',
          isClosable: false,
        });
      })
      .catch(function (error) {
        if (error.response) {
        }
        setError('שגיאה');
        toast({
          title: `שגיאה בשמירת הנתונים`,
          position: 'top',
          status: 'error',
          isClosable: false,
        });
      });
  };

  const blurStyles =
    loading === true
      ? {
          transition: 'filter 400ms',
          WebkitFilter: 'blur(16px)',
          filter: 'blur(16px)',
        }
      : {};

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent
        ref={modalContentRef}
        maxW={{ base: '90%', md: '80%', lg: '68%' }}
        maxH={{ base: '90vh', md: '80vh', lg: '95vh' }}
        overflowY="auto"
      >
        <ModalHeader>
          עריכת פרטי עובד
          {loading && <Spinner marginRight="16px" mt={1} size="md" />}
        </ModalHeader>

        <Tabs align="start" variant="enclosed" mr={2} isFitted>
          <TabList>
            <Tab isDisabled={loading}>פרטים</Tab>
            <Tab isDisabled={loading}>הרשאות וגישה</Tab>
          </TabList>
          <TabPanels>
            {/* Details TAB */}
            <TabPanel>
              <ModalBody width="100%">
                <StyledError>{error}</StyledError>

                {missingInformation.length > 0 && (
                  <Flex flexDirection="column" marginBottom="20px">
                    <Text fontWeight="bold" fontSize="18px" textColor="orange">
                      חסרים פרטים
                    </Text>

                    {missingInformation.map((value) => (
                      <MissingInformation key={value}>
                        - {prettifyMissingInformationStatus(value)}
                      </MissingInformation>
                    ))}
                  </Flex>
                )}

                <SimpleGrid columns={{ sm: 2 }} gap="32px" mb="0px" style={blurStyles}>
                  <Flex flexDirection="column">
                    <FormLabel fontWeight="bold">פרטים אישיים</FormLabel>

                    <SimpleGrid columns={{ sm: 2 }} gap="8px" mb="0px">
                      <FormControl mt={4}>
                        <Input
                          defaultValue={firstName}
                          onChange={(event) => {
                            setFirstName(event.target.value);
                          }}
                        />
                      </FormControl>

                      <FormControl mt={4}>
                        <Input
                          defaultValue={lastName}
                          placeholder="שם משפחה"
                          onChange={(event) => {
                            setLastName(event.target.value);
                          }}
                        />
                      </FormControl>

                      <FormControl mt={2}>
                        <Input
                          defaultValue={govId}
                          placeholder="תעודת זהות"
                          onChange={(event) => {
                            setGovId(event.target.value);
                          }}
                        />
                      </FormControl>

                      <FormControl mt={2}>
                        <Input
                          defaultValue={phoneNumber}
                          placeholder="טלפון"
                          onChange={(event) => {
                            setPhoneNumber(event.target.value);
                          }}
                        />
                      </FormControl>

                      <FormControl mt={2}>
                        <Input
                          defaultValue={dob}
                          type="date"
                          placeholder="תאריך לידה"
                          onChange={(event) => {
                            setDob(event.target.value);
                          }}
                        />
                      </FormControl>

                      <StyledSelect
                        mt={2}
                        value={gender}
                        placeholder="בחר מגדר"
                        name="gender"
                        onChange={(event) => {
                          setGender(event.target.value);
                        }}
                      >
                        <option value="male">גבר</option>
                        <option value="female">אישה</option>
                        <option value="other">אחר</option>
                      </StyledSelect>

                      <FormControl mt={2}>
                        <AutoCompleteSelector
                          state={cityId}
                          stateSetter={setCityId}
                          data={citiesData}
                        />
                      </FormControl>

                      <StyledSelect
                        mt={2}
                        defaultValue={status}
                        key={status}
                        placeholder="בחר סטטוס"
                        name="status"
                        onChange={(event) => {
                          if (event.target.value == 400) {
                            const currentDate = new Date();
                            setEndDate(currentDate);
                          }
                          setStatus(event.target.value);
                        }}
                      >
                        <option value="100">לא פעיל</option>
                        <option value="200">פעיל</option>
                        <option value="300">חסום</option>
                        <option value="400">עזב</option>
                      </StyledSelect>
                    </SimpleGrid>

                    <Textarea
                      defaultValue={notes !== '' ? notes : undefined}
                      placeholder="הוסף הערה"
                      onChange={(event) => {
                        setNotes(event.target.value);
                      }}
                      rows={5}
                      cols={100}
                      mt={6}
                    />

                    <FormLabel mt={10} fontWeight="bold">
                      פרטי מעסיק
                    </FormLabel>

                    <SimpleGrid mt={4} columns={{ lg: 2 }} gap="16px" mb="24px">
                      <FormControl>
                        <Input
                          defaultValue={internalId}
                          placeholder="מס עובד"
                          onChange={(event) => {
                            setInternalId(event.target.value);
                          }}
                        />
                      </FormControl>

                      <FormControl>
                        <Input
                          defaultValue={integrationUid}
                          placeholder="מזהה נוכחות"
                          onChange={(event) => {
                            setIntegrationUid(event.target.value);
                          }}
                        />
                      </FormControl>

                      <FormControl>
                        <Input
                          defaultValue={jobDepartment}
                          placeholder="מחלקה"
                          onChange={(event) => {
                            setJobDepartment(event.target.value);
                          }}
                        />
                      </FormControl>

                      <FormControl>
                        <Input
                          defaultValue={jobTitle}
                          placeholder="תפקיד"
                          onChange={(event) => {
                            setJobTitle(event.target.value);
                          }}
                        />
                      </FormControl>

                      <FormControl>
                        <Input
                          defaultValue={branch}
                          placeholder="סניף"
                          onChange={(event) => {
                            setBranch(event.target.value);
                          }}
                        />
                      </FormControl>
                    </SimpleGrid>

                    <FormLabel fontWeight="bold">פרטי חשבון בנק</FormLabel>

                    <SimpleGrid mt={4} columns={{ lg: 3 }} gap="16px" mb="0px">
                      <StyledSelect
                        value={bankId}
                        key={bankId}
                        placeholder="בחר בנק"
                        onChange={(event) => {
                          setBankId(event.target.value);
                        }}
                      >
                        {BANKS_LIST.map((bank) => (
                          <option value={bank.value}>{bank.label}</option>
                        ))}
                      </StyledSelect>

                      <FormControl>
                        <Input
                          defaultValue={branchId}
                          placeholder="סניף"
                          onChange={(event) => {
                            setBranchId(event.target.value);
                          }}
                        />
                      </FormControl>

                      <FormControl>
                        <Input
                          defaultValue={accountId}
                          placeholder="חשבון"
                          onChange={(event) => {
                            setAccountId(event.target.value);
                          }}
                        />
                      </FormControl>
                    </SimpleGrid>

                    <Input
                      mt={4}
                      defaultValue={bankRecipientName}
                      placeholder="מוטב להעברה"
                      onChange={(event) => {
                        setBankRecipientName(event.target.value);
                      }}
                    />
                  </Flex>

                  <Flex mt={2} backgroundColor="transparent" flexDirection="column">
                    <FormLabel fontWeight="bold">פרטי שכר</FormLabel>

                    <SimpleGrid columns={{ sm: 2 }} mb="0px" gap="8px" templateColumns="1fr 2fr">
                      <FormControl mt={4}>
                        <Text>יחס צבירה</Text>
                      </FormControl>

                      <FormControl mt={2}>
                        <Input
                          defaultValue={availableToTransferFactor}
                          onChange={(event) => {
                            setSalaryChanged(true);
                            setAvailableToTransferFactor(event.target.value);
                          }}
                        />
                      </FormControl>

                      <FormControl mt={4}>
                        <Text>שכר שעתי ברוטו</Text>
                      </FormControl>

                      <FormControl mt={2}>
                        <Input
                          defaultValue={hourlyGrossRate}
                          onChange={(event) => {
                            setSalaryChanged(true);
                            setHourlyGrossRate(event.target.value);
                          }}
                        />
                      </FormControl>

                      <FormControl mt={4}>
                        <Text>שכר חודשי ברוטו</Text>
                      </FormControl>

                      <FormControl mt={2}>
                        <Input
                          defaultValue={monthlyGrossRate}
                          onChange={(event) => {
                            setSalaryChanged(true);
                            setMonthlyGrossRate(event.target.value);
                          }}
                        />
                      </FormControl>

                      <FormControl mt={4}>
                        <Text>שכר צבור</Text>
                      </FormControl>

                      <FormControl mt={2}>
                        <Input readOnly={true} defaultValue={earnedPay} />
                      </FormControl>
                    </SimpleGrid>

                    <Flex marginTop="16px" alignItems="center" justifyContent="space-between">
                      <FormLabel fontWeight="bold" mt={2}>
                        נוכחות החודש
                      </FormLabel>
                      <Button
                        marginLeft="16px"
                        height="36px"
                        backgroundColor="green.200"
                        onClick={downloadEmployeeShiftsReport}
                      >
                        הורדה
                      </Button>
                    </Flex>

                    <SimpleGrid
                      columns={{ sm: 2 }}
                      mt={2}
                      gap="8px"
                      mb="0px"
                      templateColumns="1fr 2fr"
                    >
                      <FormControl mt={4}>
                        <Text>מספר משמרות</Text>
                      </FormControl>

                      <FormControl mt={2}>
                        <Input readOnly={true} defaultValue={shiftsCount} />
                      </FormControl>

                      <FormControl mt={4}>
                        <Text>סה״כ שעות עבודה</Text>
                      </FormControl>

                      <FormControl mt={2}>
                        <Input readOnly={true} defaultValue={totalWorkingHours} />
                      </FormControl>
                    </SimpleGrid>

                    <Flex marginTop="16px" alignItems="center" justifyContent="space-between">
                      <FormLabel fontWeight="bold" mt={2}>
                        העברות החודש
                      </FormLabel>
                      <Button
                        marginLeft="16px"
                        height="36px"
                        backgroundColor="green.200"
                        onClick={downloadTransfersReport}
                      >
                        הורדה
                      </Button>
                    </Flex>

                    <SimpleGrid
                      columns={{ sm: 2 }}
                      gap="8px"
                      mb="0px"
                      templateColumns="1fr 2fr"
                      mt={2}
                    >
                      <FormControl mt={4}>
                        <Text>סה״כ העברות</Text>
                      </FormControl>

                      <FormControl mt={2}>
                        <Input readOnly={true} defaultValue={totalTransferred} />
                      </FormControl>

                      <FormControl mt={4}>
                        <Text>יתרה להעברה</Text>
                      </FormControl>

                      <FormControl mt={2}>
                        <Input readOnly={true} defaultValue={availableToTransfer} />
                      </FormControl>

                      <FormControl mt={4}>
                        <Text>מסגרת חודשית</Text>
                      </FormControl>
                      <FormControl mt={2}>
                        <Input
                          defaultValue={employeeTransferLimit}
                          onInput={(event) => {
                            setEmployeeTransferLimit(
                              event.target.value === '' ? null : event.target.value,
                            );
                          }}
                        />
                      </FormControl>
                    </SimpleGrid>
                  </Flex>
                </SimpleGrid>
              </ModalBody>
              <ModalFooter>
                <Flex justifyContent="flex-end" gap="16px" width="100%">
                  <Button onClick={updateEmployee}>שמור</Button>
                  <Button onClick={onClose}>סגור</Button>
                </Flex>
              </ModalFooter>
            </TabPanel>

            {/* USER ACCESS TAB */}
            <TabPanel>
              <UserAccess
                userRoleAccess={userRoleAccess}
                onClose={onClose}
                primaryCompanyId={employee?.companyId || ''}
                userId={employee?.id}
                callBack={callBack}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </ModalContent>
    </Modal>
  );
}

// ----------------------------------- Styled Components -----------------------------------

const StyledError = styled.h4`
  font-size: 1.2em;
  color: red;
  text-align: center;
  font-weight: 400;
  transition: '.2s all';
`;

const MissingInformation = styled.h4`
  font-size: 1em;
  text-align: right;
  font-weight: 600;
  transition: '.2s all';
`;

const StyledSelect = styled(Select)`
  padding-inline: 32px 16px;
`;
