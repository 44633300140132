import * as Yup from 'yup';

export const formFields = {
  isActive: {
    name: 'isActive',
    label: 'אירגון פעיל',
    type: 'switch',
  },
  name: {
    name: 'name',
    label: 'שם אירגון',
    type: 'input',
    placeholder: 'שם אירגון',
  },
  companies: {
    name: 'companies',
    label: 'חברות',
    type: 'select',
    placeholder: 'בחירת חברות',
  },
};

export const validationSchema = Yup.object({
  name: Yup.string().required('שם אירגון הוא שדה חובה'),
});
