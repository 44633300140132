import { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { useToast, useDisclosure } from '@chakra-ui/react';

import IntegrationsApi from '../../../../../api/integrations';
import { resultsPerPageOptions } from '../consts/tableConsts';
import { useCompaniesOptions } from '../../../../../hooks/useCompaniesOptions';

export const PipelineResultsContext = createContext();

export const PipelineResultsProvider = ({ children }) => {
  const [data, setData] = useState([]);
  const [resultsPerPage, setResultsPerPage] = useState(resultsPerPageOptions[0]);
  const [pageIndex, setPageIndex] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [selectedPipeline, setSelectedPipeline] = useState(null);
  const [dataLoading, setDataLoading] = useState(false);
  const [filters, setFilters] = useState({});
  const [appliedFilters, setAppliedFilters] = useState({});

  const toast = useToast();

  const { isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose } = useDisclosure();

  const { companiesOptions } = useCompaniesOptions({ showAll: true });

  const updateResultsPerPage = (newSize) => {
    if (resultsPerPageOptions.includes(newSize)) {
      setResultsPerPage(newSize);
      // Reset to first page when changing page size
      setPageIndex(0);
    } else {
      console.warn('Invalid page size provided');
    }
  };

  const updatePageIndex = (newIndex) => {
    if (newIndex >= 0 && newIndex < totalPages) {
      setPageIndex(newIndex);
    }
  };

  const selectPipeline = (pipeline) => {
    setSelectedPipeline(pipeline);
    onModalOpen();
  };

  const updateFilters = (key, value) => {
    if (!value) {
      const currentFilters = { ...filters };
      delete currentFilters[key];
      setFilters(currentFilters);
      return;
    }
    setFilters({ ...filters, [key]: value });
  };

  const resetFilters = () => {
    setFilters({});
    if (Object.keys(appliedFilters)?.length) {
      setAppliedFilters({});
    }
  };

  const handleError = useCallback(
    (error) => {
      console.error('Failed: ', error);
      toast({
        title: 'שגיאה',
        description: 'אנא נסה שנית מאוחר יותר',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top',
      });
    },
    [toast],
  );

  const refreshDataPipelines = useCallback(async () => {
    try {
      setDataLoading(true);
      const res = await IntegrationsApi.getIntegrationsResults(
        {
          limit: resultsPerPage,
          page: pageIndex + 1, // API expects 1-based index
        },
        appliedFilters,
      );

      if (!res?.data?.integrations) {
        throw new Error('No data received');
      }

      setData(res.data.integrations);
      setTotalCount(res.data.totalCount || 0);
      setTotalPages(res.data.totalPages || 0);
    } catch (e) {
      handleError(e);
    } finally {
      setDataLoading(false);
    }
  }, [resultsPerPage, pageIndex, handleError, appliedFilters]);

  const applyFilters = () => {
    setPageIndex(0); // Reset to first page when apply filters
    setAppliedFilters((currentAppliedFilters) => ({ ...filters })); // this will trigger the refreshDataPipelines
  };

  const handleModalClose = () => {
    onModalClose();
    setSelectedPipeline(null);
  };

  useEffect(() => {
    refreshDataPipelines();
  }, [refreshDataPipelines]);

  return (
    <PipelineResultsContext.Provider
      value={{
        isModalOpen,
        onModalOpen,
        onModalClose: handleModalClose,
        toast,
        refreshDataPipelines,
        integrationData: data,
        resultsPerPage,
        updateResultsPerPage,
        pageIndex,
        updatePageIndex,
        totalCount,
        totalPages,
        selectedPipeline,
        selectPipeline,
        dataLoading,
        companiesOptions,
        filters,
        updateFilters,
        resetFilters,
        appliedFilters,
        applyFilters,
      }}
    >
      {children}
    </PipelineResultsContext.Provider>
  );
};

export const usePipelineResults = () => {
  return useContext(PipelineResultsContext);
};
