import { useState } from 'react';
import {
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Button,
  useColorModeValue,
} from '@chakra-ui/react';
import { useSortBy, useTable } from 'react-table';

import TableSkeleton from './TableSkeleton';
import { tableColumns, TABLE_COL_TYPES } from '../consts/tableConsts';
import { useOrganizations } from '../context/OrganizationsContext';

export default function OrganizationsTable() {
  const [deletingIds, setDeletingIds] = useState([]);
  const {
    organizations,
    handleEditModalOpen,
    onDelete,
    loaders: { isTableLoading },
  } = useOrganizations();

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    { columns: tableColumns, data: organizations },
    useSortBy,
  );

  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');

  const handleEdit = (cellRow) => {
    handleEditModalOpen(cellRow?.values);
  };

  const handleDelete = async (id) => {
    setDeletingIds((prev) => [...prev, id]);
    try {
      await onDelete(id);
    } finally {
      setDeletingIds((prev) => prev.filter((deletingId) => deletingId !== id));
    }
  };

  return (
    <>
      {isTableLoading ? (
        <TableSkeleton />
      ) : (
        <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
          <Thead>
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    pe="10px"
                    key={index}
                    borderColor={borderColor}
                  >
                    <Flex
                      justify="space-between"
                      align="center"
                      fontSize={{ sm: '10px', lg: '12px' }}
                      color="gray.400"
                    >
                      {column.render('Header')}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()} key={row.id}>
                  {row.cells.map((cell, index) => {
                    let data = '';
                    let cellWidth = cell.column.width;
                    if (cell.column.type === TABLE_COL_TYPES.TEXT) {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="700">
                          {cell.value}
                        </Text>
                      );
                    } else if (cell.column.type === TABLE_COL_TYPES.BUTTON) {
                      data = (
                        <Flex align="center" gap="8px">
                          <Button
                            textColor="black"
                            backgroundColor="green.200"
                            size="sm"
                            onClick={() => handleEdit(row)}
                            isDisabled={deletingIds.includes(row.original.id)}
                          >
                            עריכה
                          </Button>
                          <Button
                            textColor="black"
                            backgroundColor="red.200"
                            size="sm"
                            isLoading={deletingIds.includes(row.original.id)}
                            onClick={() => handleDelete(row.original.id)}
                          >
                            מחיקה
                          </Button>
                        </Flex>
                      );
                    } else if (cell.column.type === TABLE_COL_TYPES.LIST) {
                      data = (
                        <Flex gap="4px" direction="column">
                          {cell.value.map((item) => (
                            <Text color={textColor} fontSize="sm" fontWeight="500" key={item.id}>
                              {item.name}
                            </Text>
                          ))}
                        </Flex>
                      );
                    } else if (cell.column.type === TABLE_COL_TYPES.BOOLEAN) {
                      const isOrganizationActive = cell.value;
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="500">
                          {isOrganizationActive ? 'פעיל' : 'לא פעיל'}
                        </Text>
                      );
                    }
                    return (
                      <Td
                        {...cell.getCellProps()}
                        key={index}
                        fontSize={{ sm: '14px' }}
                        width={cellWidth}
                        maxWidth={cellWidth}
                      >
                        {data}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      )}
    </>
  );
}
