import { Flex, Button } from '@chakra-ui/react';
import { useOrganizations } from '../context/OrganizationsContext';

const OrganizationsTopSection = () => {
  const { handleCreateModalOpen } = useOrganizations();
  return (
    <Flex px="25px" justify="space-between" mb="20px" align="center">
      <Button variant="blue" onClick={handleCreateModalOpen}>
        הוספת אירגון
      </Button>
    </Flex>
  );
};

export default OrganizationsTopSection;
