import { Button, Flex, Select } from '@chakra-ui/react';
import { MultiSelect } from 'chakra-multiselect';
import { GoChevronDown } from 'react-icons/go';

import DateRangePicker from 'components/datePicker/DateRangePicker';
import FiltersAppliedInfoSection from './FiltersAppliedInfoSection';
import { usePipelineResults } from '../context/PipelineResultsContext';
import { isValueNonEmpty } from 'functions/common';

const PipelinesFilters = () => {
  const { companiesOptions } = usePipelineResults();
  const { filters, updateFilters, resetFilters, applyFilters, appliedFilters } =
    usePipelineResults();

  const hasFilters = Object.values(filters).some(isValueNonEmpty);
  const hasAppliedFilters = Object.values(appliedFilters).some(isValueNonEmpty);

  return (
    <>
      <Flex mb={6} gap={3} alignItems="center" paddingInline={4}>
        <DateRangePicker
          value={filters.dates}
          onChange={(newDates) => updateFilters('dates', newDates)}
          width="192px"
        />

        <Select
          width="192px"
          placeholder="בחר סטטוס"
          name="status"
          onChange={(e) => {
            updateFilters('status', e.target.value);
          }}
          value={filters?.status || ''}
          color={filters?.status ? 'inherit' : 'gray.400'}
          iconColor="black"
          icon={<GoChevronDown />}
          style={{
            paddingInline: '32px 16px',
          }}
        >
          <option value="success">הצלחה</option>
          <option value="loader_failure">שגיאה</option>
        </Select>

        <MultiSelect
          options={companiesOptions}
          onChange={(newValue) => {
            updateFilters('company', newValue);
          }}
          placeholder="בחר חברה"
          single
          value={filters?.company || ''}
        />

        <Button width="80px" variant="brand" onClick={applyFilters}>
          הצג
        </Button>

        {hasFilters && (
          <Button variant="outline" onClick={resetFilters} mr={4}>
            נקה
          </Button>
        )}
      </Flex>

      {hasAppliedFilters && <FiltersAppliedInfoSection />}
    </>
  );
};

export default PipelinesFilters;
