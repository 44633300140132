import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent, AlertDialogFooter, AlertDialogHeader,
    AlertDialogOverlay,
    Button,
    Modal,
    ModalBody,
    ModalContent, ModalFooter,
    ModalHeader,
    ModalOverlay, SimpleGrid, Text, useDisclosure, useToast,
    Tooltip,
} from "@chakra-ui/react";
import React, {useEffect, useRef} from "react";
import EditCompanyModal from "./EditCompanyModal";
import TaReportCompanyModal from "./TaReportCompanyModal";
import CompaniesApi from "../../../../api/companies";
import EmployeesApi from "../../../../api/employees";
import IntegrationsApi from "../../../../api/integrations";
import AdminTestIntegrationCredentialsModal from "./AdminTestIntegrationCredentials";

export default function CompanyDetailsModal(props) {
    let { isOpen, onClose, company, callBack, ...rest } = props;
    const [selectedCompany, setSelectedCompany] = React.useState(null);
    const [companyAdoptionRate, setCompanyAdoptionRate] = React.useState(0);

    const {
        isOpen: isEditCompanyOpen,
        onOpen: onEditCompanyOpen,
        onClose: onEditCompanyClose
    } = useDisclosure();

    const {
        isOpen: isDeleteCompanyOpen,
        onOpen: onDeleteCompanyOpen,
        onClose: onDeleteCompanyClose
    } = useDisclosure();

    const {
        isOpen: isTaReportOpen,
        onOpen: onTaReportOpen,
        onClose: onTaReportClose
    } = useDisclosure();

    const {
        isOpen: isCredentialsTestModalOpen,
        onOpen: onCredentialsTestModalOpen,
        onClose: onCredentialsTestModalClose
    } = useDisclosure();

    const toast = useToast();
    const fileInputRef = useRef(); // Create a ref for the file input

    const handleUploadAlfonFile = () => {
        fileInputRef.current.click();
    };

    const handleAttendanceSync = () => {
       IntegrationsApi.triggerAttendanceIntegrationActionByCompanyId(selectedCompany.id).then(res => {
              toast({
                title: `הסנכרון הופעל בהצלחה`,
                position: 'top',
                status: 'success',
                isClosable: false,
              });
       }).catch(e => {
           toast({
               title: `שגיאה בסנכרון`,
               position: 'top',
               status: 'error',
               isClosable: false,
           });
       })
    };

    const handleCredentialsTestClick = () => {
        onCredentialsTestModalOpen();
    };


    const handleFileChange = (event) => {
        const fileObj = event.target.files && event.target.files[0];
        if (!fileObj) {
            return;
        }
        uploadFile(fileObj, selectedCompany.id);
    };

    useEffect(() => {
        if (isOpen) {
            if (company) {
                setSelectedCompany(company);
            }
        } else {
            setSelectedCompany(null);
        }
    }, [isOpen, company]);

    useEffect(() => {
        if(selectedCompany) {
            const companyAdoptionRateInt = (selectedCompany.activeUsersCount / selectedCompany.employeesCount) * 100;
            setCompanyAdoptionRate(companyAdoptionRateInt.toFixed(0));
        }
    }, [selectedCompany]);



    const cancelRef = React.useRef();

    const handleEditClick = () => {
        onEditCompanyOpen();
    };

    const handleDeleteClick = () => {
        onDeleteCompanyOpen();
    };

    const handleTaReportClick = () => {
        onTaReportOpen();
    };

    const handleDeleteConfirm = () => {
        CompaniesApi.delete(company.id).then(res => {
            CompaniesApi.getAll().then(res => {
                onDeleteCompanyClose();
            });
        })
            .catch((error) => {
                if (error.response) {
                    // handle error
                }
            });
    };

    const uploadFile = (selectedFile, companyId) => {
        EmployeesApi.uploadFile(selectedFile, companyId).then(res => {
            if(res.data) {
                toast({
                    title: `קובץ נשלח בהצלחה`,
                    position: 'top',
                    status: 'success',
                    isClosable: false,
                });
                setTimeout(() => {
                    onClose();
                }, 2000);
            }
        }).catch(e => {
            toast({
                title: `שגיאה בקובץ`,
                position: 'top',
                status: 'error',
                isClosable: false,
            });
        });
    };

    const calculatePercentage = (value, total) => {
        if (total === 0) return "0%";
        return `${((value / total) * 100).toFixed(0)}%`
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay />
            <ModalContent maxH="640px" maxW="500px">
                <ModalHeader>פרטי חברה {selectedCompany ? selectedCompany.name : ""}</ModalHeader>
                <ModalBody>
                    <EditCompanyModal company={selectedCompany} isOpen={isEditCompanyOpen} onClose={onEditCompanyClose} />
                    <TaReportCompanyModal company={selectedCompany} isOpen={isTaReportOpen} onClose={onTaReportClose} />
                    <AdminTestIntegrationCredentialsModal company={selectedCompany} isOpen={isCredentialsTestModalOpen} onClose={onCredentialsTestModalClose} />

                    <SimpleGrid marginTop="10px" columns={{ sm: 2, md: 3 }} gap="5px" mb="0px">
                        <Button variant="brand" onClick={handleEditClick}>עריכה</Button>
                        <Button variant="brand" onClick={handleTaReportClick}>דו״ח נוכחות</Button>
                        <Button variant="brand" onClick={handleUploadAlfonFile}>אלפון</Button>
                        <Button variant="brand" onClick={handleCredentialsTestClick}>בדיקת אלפון</Button>
                        <Button variant="brand" onClick={handleAttendanceSync}>סנכרון נוכחות</Button>
                        <input type="file" ref={fileInputRef} onChange={handleFileChange} style={{ display: 'none' }} /> {/* Hidden file input */}
                        <Button backgroundColor="red.200" onClick={handleDeleteClick}>מחיקה</Button>
                        <Text></Text>
                        <Text></Text>
                    </SimpleGrid>

                        <SimpleGrid columns={{ sm: 3, md: 3 }} gap="15px" mt="20px" mb="0px">
                            <Text>סה״כ עובדים</Text>
                            <Text align="center" >{selectedCompany ? selectedCompany.employeesCount : 0}</Text>
                            <Text></Text>

                            <Text>סה״כ רשומים</Text>
                            <Text align="center">{selectedCompany ? selectedCompany.activeUsersCount : 0}</Text>
                            <Text align="center">{selectedCompany ? calculatePercentage(selectedCompany.activeUsersCount, selectedCompany.employeesCount) : "0%"}</Text>

                            <Text>סה״כ משתמשים ייחודיים שמשכו</Text>
                            <Text align="center">{selectedCompany ? selectedCompany.uniqueUsersRequestedTotal : 0}</Text>
                            <Text align="center">{selectedCompany ? calculatePercentage(selectedCompany.uniqueUsersRequestedTotal, selectedCompany.employeesCount) : "0%"}</Text>

                            <Text>משתמשים ייחודיים החודש שמשכו</Text>
                            <Text align="center">{selectedCompany ? selectedCompany.uniqueUsersRequestedCurrentMonth : 0}</Text>
                            <Text align="center">{selectedCompany ? calculatePercentage(selectedCompany.uniqueUsersRequestedCurrentMonth, selectedCompany.employeesCount) : "0%"}</Text>

                            <Text>העברות החודש</Text>
                            <Text align="center">{selectedCompany ? selectedCompany.transfersCountCurrentMonth : 0}</Text>
                            <Text align="center"></Text>

                            <Text>סה״כ העברות</Text>
                            <Text align="center">{selectedCompany ? selectedCompany.transfersCountTotal : 0}</Text>
                            <Text></Text>

                            <Text>ארגון</Text>
                            <Tooltip isDisabled={!selectedCompany?.organization?.name} label={selectedCompany?.organization?.name || ''}>
                                <Text align="center" isTruncated>{selectedCompany?.organization?.name || '-'}</Text>
                            </Tooltip>
                            <Text></Text>


                            <Text>קישור להורדה</Text>

                            <Button onClick={() => navigator.clipboard.writeText(selectedCompany ?  "https://mobile.payro.io/signUp?referrer=" + selectedCompany.externalId : 0)}>
                                העתק
                            </Button>

                            {/*<Text align="center">{selectedCompany ?  "https://mobile.payro.io/signUp?referrer=" + selectedCompany.externalId : 0}</Text>*/}
                            <Text></Text>

                        </SimpleGrid>

                        <Text></Text>
                        <Text></Text>

                        {/*<Text></Text>*/}
                        {/*<Text marginTop="15px" w="100%">סה״כ רשומים</Text>*/}
                        {/*<Text marginTop="15px" w="100%">{selectedCompany ? selectedCompany.activeUsersCount : 0} ({companyAdoptionRate}%)</Text>*/}

                        {/*<Text></Text>*/}
                        {/*<Text marginTop="15px" w="100%">סה״כ משתמשים ייחודיים שמשכו</Text>*/}
                        {/*<Text marginTop="15px" w="100%">{selectedCompany ? selectedCompany.uniqueUsersRequestedTotal : 0}</Text>*/}

                        {/*<Text></Text>*/}
                        {/*<Text marginTop="15px" w="100%">משתמשים ייחודיים החודש שמשכו</Text>*/}
                        {/*<Text marginTop="15px" w="100%">{selectedCompany ? selectedCompany.uniqueUsersRequestedCurrentMonth : 0}</Text>*/}

                        {/*<Text></Text>*/}
                        {/*<Text marginTop="15px" w="100%">העברות החודש</Text>*/}
                        {/*<Text marginTop="15px" w="100%">{selectedCompany ? selectedCompany.transfersCountCurrentMonth : 0}</Text>*/}

                        {/*<Text></Text>*/}
                        {/*<Text marginTop="15px" w="100%">סה״כ העברות</Text>*/}
                        {/*<Text marginTop="15px" w="100%">{selectedCompany ? selectedCompany.transfersCountTotal : 0}</Text>*/}

                    <AlertDialog isOpen={isDeleteCompanyOpen} onClose={onDeleteCompanyClose} isCentered>
                        <AlertDialogOverlay>
                            <AlertDialogContent>
                                <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                                    מחיקה חברה
                                </AlertDialogHeader>
                                <AlertDialogBody>
                                    האם ברצונך למחוק את {selectedCompany ? selectedCompany.name : ""}?
                                </AlertDialogBody>
                                <AlertDialogFooter>
                                    <Button ref={cancelRef} onClick={onDeleteCompanyClose}>
                                        ביטול
                                    </Button>
                                    <Button colorScheme='red' onClick={handleDeleteConfirm} ml={3}>
                                        מחיקה
                                    </Button>
                                </AlertDialogFooter>
                            </AlertDialogContent>
                        </AlertDialogOverlay>
                    </AlertDialog>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={onClose}>סגור</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}
