import { Flex, Text, useColorModeValue } from '@chakra-ui/react';

import PipelinesTable from './components/PipelinesTable';
import Card from '../../../../components/card/Card';
import PipelinesFilters from './components/PipelinesFilters';
import { PipelineResultsProvider } from './context/PipelineResultsContext';

const PipelineResults = () => {
  const textColor = useColorModeValue('secondaryGray.900', 'white');

  return (
    <PipelineResultsProvider>
      <Card
        direction="column"
        w="100%"
        px="0px"
        overflowX={{ sm: 'scroll', lg: 'hidden' }}
        minH="480px"
      >
        <Flex px="25px" justify="space-between" mb="20px" align="center">
          <Text color={textColor} fontSize="22px" fontWeight="700" lineHeight="100%">
            Pipelines
          </Text>
        </Flex>
        <PipelinesFilters />
        <PipelinesTable />
      </Card>
    </PipelineResultsProvider>
  );
};

export default PipelineResults;
