import { useState, Fragment } from 'react';
import {
  Button,
  ModalBody,
  ModalFooter,
  Box,
  FormLabel,
  Input,
  IconButton,
  Flex,
  SimpleGrid,
  Select,
  FormControl,
  Tooltip,
  Text,
  useToast,
} from '@chakra-ui/react';
import { IoMdAdd } from 'react-icons/io';
import { MdClear } from 'react-icons/md';
import { MdHomeWork } from 'react-icons/md';
import { Formik, Field, Form, ErrorMessage, FieldArray } from 'formik';
import * as Yup from 'yup';
import styled from '@emotion/styled';

import UserAccessSkeleton from './UserAccessSkeleton';
import { rolesWithoutSuperAdmin, globalScopedRoles } from '../data/roles';
import EmployeesApi from '../../../../api/employees';

const validationSchema = Yup.object().shape({
  userRoleAccess: Yup.array()
    .min(1, 'חייבת להיות לפחות הרשאה אחת')
    .of(
      Yup.object().shape({
        role: Yup.string().required('יש לבחור הרשאה'),
        companyId: Yup.mixed().test(
          'companyOrOrgRequired',
          'יש לבחור חברה או אירגון',
          function (value) {
            const { organizationId, role } = this.parent;
            if (!globalScopedRoles.includes(role)) {
              return !!value || !!organizationId;
            }
            return true;
          },
        ),
        organizationId: Yup.mixed().test(
          'companyOrOrgRequired',
          'יש לבחור חברה או אירגון',
          function (value) {
            const { companyId, role } = this.parent;
            if (!globalScopedRoles.includes(role)) {
              return !!value || !!companyId;
            }
            return true;
          },
        ),
      }),
    ),
});

const UserAccess = ({ userRoleAccess, onClose, primaryCompanyId, userId, callBack }) => {
  const [isLoading, setIsLoading] = useState(false);

  const toast = useToast();

  const initialValues = {
    userRoleAccess: userRoleAccess ? userRoleAccess : [],
  };

  const onSubmit = async (values) => {
    try {
      setIsLoading(true);

      const payload = Object.values(values.userRoleAccess).map(
        ({ role, companyId, organizationId }) => ({
          role,
          ...(companyId && { companyId: Number(companyId) }),
          ...(organizationId && { organizationId: Number(organizationId) }),
        }),
      );

      await EmployeesApi.updateUserAccess(userId, payload);
      toast({
        title: 'הרשאות עובד נשמרו בהצלחה',
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'top',
      });
      callBack();
      onClose();
    } catch (error) {
      console.error(error);
      toast({
        title: 'שגיאה בשמירת הרשאות',
        description: 'אנא נסה שנית מאוחר יותר',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <ModalBody>
            {isLoading ? (
              <UserAccessSkeleton />
            ) : (
              <>
                <FieldArray name="userRoleAccess">
                  {({ push, remove }) => (
                    <>
                      <SimpleGrid
                        columns={5}
                        mb={4}
                        gap={3}
                        gridTemplateColumns="auto 8fr 8fr 8fr 1fr"
                        alignItems="center"
                      >
                        {!!values?.userRoleAccess?.length && (
                          <>
                            <Box minW="20px" />
                            <FormLabel>הרשאה</FormLabel>
                            <FormLabel>חברה</FormLabel>
                            <FormLabel>אירגון</FormLabel>
                            <Box minW="20px" />
                          </>
                        )}

                        {values.userRoleAccess.map((entry, index) => {
                          const isGlobalRole = globalScopedRoles.includes(entry.role);
                          const isPrimaryCompany = entry.companyId == primaryCompanyId;

                          return (
                            <Fragment key={index}>
                              {isPrimaryCompany ? (
                                <Tooltip
                                  placement="top"
                                  label="החברה שמוגדרת כמעסיק הראשי של העובד"
                                >
                                  <Box mb={2}>
                                    <MdHomeWork />
                                  </Box>
                                </Tooltip>
                              ) : (
                                <Box minW="20px" />
                              )}
                              <FormControl>
                                <Field
                                  name={`userRoleAccess[${index}].role`}
                                  as={StyledSelect}
                                  onChange={(e) => {
                                    const selectedRole = e.target.value;
                                    setFieldValue(`userRoleAccess[${index}].role`, selectedRole);

                                    if (globalScopedRoles.includes(selectedRole)) {
                                      setFieldValue(`userRoleAccess[${index}].companyId`, '');
                                      setFieldValue(`userRoleAccess[${index}].organizationId`, '');
                                    }
                                  }}
                                >
                                  <option value="">בחר הרשאה</option>
                                  {rolesWithoutSuperAdmin.map((role) => (
                                    <option key={role.value} value={role.value}>
                                      {role.label}
                                    </option>
                                  ))}
                                </Field>
                                <Box minH="14px">
                                  <ErrorMessage
                                    name={`userRoleAccess[${index}].role`}
                                    component={ErrorMessageText}
                                  />
                                </Box>
                              </FormControl>

                              <FormControl>
                                <Field
                                  name={`userRoleAccess[${index}].companyId`}
                                  as={Input}
                                  placeholder="בחר חברה"
                                  disabled={isGlobalRole || entry.organizationId}
                                />
                                <Box minH="14px">
                                  <ErrorMessage
                                    name={`userRoleAccess[${index}].companyId`}
                                    component={ErrorMessageText}
                                  />
                                </Box>
                              </FormControl>

                              <FormControl>
                                <Field
                                  name={`userRoleAccess[${index}].organizationId`}
                                  as={Input}
                                  placeholder="בחר אירגון"
                                  disabled={isGlobalRole || entry.companyId}
                                />
                                <Box minH="14px">
                                  <ErrorMessage
                                    name={`userRoleAccess[${index}].organizationId`}
                                    component={ErrorMessageText}
                                  />
                                </Box>
                              </FormControl>

                              <FormControl>
                                <IconButton
                                  icon={<MdClear />}
                                  size="sm"
                                  variant="ghost"
                                  onClick={() => remove(index)}
                                  disabled={values.userRoleAccess.length === 1}
                                  mb={2}
                                />
                              </FormControl>
                            </Fragment>
                          );
                        })}
                      </SimpleGrid>

                      <Flex justify="flex-start">
                        <Button
                          mr={8}
                          leftIcon={<IoMdAdd />}
                          size="sm"
                          variant="outline"
                          onClick={() => push({ role: '', companyId: '', organizationId: '' })}
                        >
                          הוספה
                        </Button>
                      </Flex>
                    </>
                  )}
                </FieldArray>
              </>
            )}
          </ModalBody>

          <ModalFooter>
            <Flex justifyContent="flex-end" gap="16px" width="100%">
              <Button isLoading={isLoading} type="submit">
                שמור
              </Button>
              <Button onClick={onClose}>סגור</Button>
            </Flex>
          </ModalFooter>
        </Form>
      )}
    </Formik>
  );
};

export default UserAccess;

// ----------------------------------- Styled Components -----------------------------------

const StyledSelect = styled(Select)`
  padding-inline: 32px 16px;
`;

const ErrorMessageText = ({ children }) => (
  <Box minHeight="14px">
    <Text color="red.500" fontSize="sm">
      {children}
    </Text>
  </Box>
);
