import { useState, useEffect, useRef } from 'react';
import {
  Button,
  FormControl,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
  useColorModeValue,
  Flex,
  Icon,
  useDisclosure,
  useOutsideClick,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import { he } from 'date-fns/locale';
import { GoChevronDown } from 'react-icons/go';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';

import { StyledCalendarMenu } from './style';

const timeZone = 'Asia/Jerusalem';
const weekStartsOn = 0;

const DateRangePicker = ({
  value,
  onChange,
  isDropdown = true,
  label = 'תאריכים',
  width = '100%',
  showSelectedDates = true,
  dateFormat = 'dd/MM/yy',
  fromLabel = 'מתאריך:',
  toLabel = 'עד תאריך:',
  direction = 'rtl',
  animateIcon = true,
}) => {
  const [month, setMonth] = useState(new Date());
  const [selectedDates, setSelectedDates] = useState(value || { from: undefined, to: undefined });

  const { onOpen, onClose, isOpen } = useDisclosure();

  const ref = useRef();

  useOutsideClick({
    ref: ref,
    handler: () => handleClose(),
  });

  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const textColor = useColorModeValue('gray.800', 'white');
  const placeholderColor = useColorModeValue('gray.400', 'gray.300');
  const activeBorderColor = useColorModeValue('blue.500', 'blue.300');

  const hasFilter = selectedDates?.from || selectedDates?.to;

  const handleDateSelect = (newSelection) => {
    if (!newSelection) {
      newSelection = { from: undefined, to: undefined };
    }
    setSelectedDates(newSelection);

    const dateRangeAsStrings = {
      from: newSelection?.from ? newSelection.from.toISOString() : undefined,
      to: newSelection?.to ? newSelection.to.toISOString() : undefined,
    };

    onChange?.(newSelection, dateRangeAsStrings);
  };

  const handleClose = () => {
    onClose();
    if (!selectedDates?.from && !selectedDates?.to) {
      setMonth(new Date());
    }
  };

  const formatDateDisplay = () => {
    if (!selectedDates?.from && !selectedDates?.to) {
      return label;
    }
    if (selectedDates?.from && selectedDates?.to) {
      return `${format(selectedDates.from, dateFormat)} - ${format(selectedDates.to, dateFormat)}`;
    }
    if (selectedDates?.from) {
      return `${fromLabel} ${format(selectedDates.from, dateFormat)}`;
    }
    if (selectedDates?.to) {
      return `${toLabel} ${format(selectedDates.to, dateFormat)}`;
    }
    return label;
  };

  useEffect(() => {
    setSelectedDates(value || { from: undefined, to: undefined });
  }, [value]);

  const Calendar = () => (
    <StyledCalendarMenu bg={bgColor} borderRadius="md" p={2} selectedDates={selectedDates}>
      <DayPicker
        mode="range"
        selected={selectedDates}
        onSelect={handleDateSelect}
        captionLayout="dropdown"
        defaultMonth={new Date()}
        month={month}
        onMonthChange={setMonth}
        locale={he}
        numberOfMonths={1}
        dir={direction}
        timeZone={timeZone}
        weekStartsOn={weekStartsOn}
      />

      {showSelectedDates && hasFilter && (
        <Text textAlign="center" my={2} fontSize="sm" fontWeight="medium">
          {formatDateDisplay()}
        </Text>
      )}
    </StyledCalendarMenu>
  );

  if (!isDropdown) {
    return <Calendar />;
  }

  return (
    <FormControl width={width} ref={ref}>
      <Popover
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={handleClose}
        placement="bottom-end"
        closeOnBlur={false}
      >
        <PopoverTrigger>
          <Button
            as="div"
            role="button"
            width={width}
            height="40px"
            px={4}
            py={2}
            borderRadius="md"
            borderWidth="1px"
            borderColor={isOpen || hasFilter ? activeBorderColor : borderColor}
            bg={bgColor}
            _hover={{
              borderColor: isOpen ? activeBorderColor : 'gray.300',
            }}
            _active={{
              bg: bgColor,
            }}
            cursor="pointer"
            transitionProperty="common"
            transitionDuration="normal"
          >
            <Flex width="100%" justifyContent="space-between" alignItems="center">
              <Text
                color={hasFilter ? textColor : placeholderColor}
                isTruncated
                style={{ fontWeight: 'normal' }}
              >
                {formatDateDisplay()}
              </Text>
              <Icon
                as={GoChevronDown}
                transform={animateIcon && isOpen ? 'rotate(180deg)' : undefined}
                transition={animateIcon ? 'transform 0.2s' : 'none'}
                w={5}
                h={5}
              />
            </Flex>
          </Button>
        </PopoverTrigger>
        <PopoverContent p={0} width="auto" borderRadius="md" boxShadow="xl">
          <Calendar />
        </PopoverContent>
      </Popover>
    </FormControl>
  );
};

export default DateRangePicker;
