export const TagDate = 0;
export const TagCompany = 1;
export const TagProvider = 2;
export const TagDataCount = 3;
export const TagErrorsCount = 4;
export const TagStatus = 5;

export const tableColumns = [
  {
    Header: 'Date',
    accessor: 'createdAt',
    tag: TagDate,
    width: '150px',
  },
  {
    Header: 'Company',
    accessor: 'companyName',
    tag: TagCompany,
    width: '10px',
  },
  {
    Header: 'Provider',
    accessor: 'integrationProviderName',
    tag: TagProvider,
    width: '150px',
  },
  {
    Header: 'Data Count',
    accessor: 'rowsAffected',
    tag: TagDataCount,
    width: '150px',
  },
  {
    Header: 'Errors Count',
    accessor: 'errorsCount',
    tag: TagErrorsCount,
    width: '150px',
  },
  {
    Header: 'Status',
    accessor: 'status',
    tag: TagStatus,
    width: '150px',
  },
];

export const resultsPerPageOptions = [10, 20, 30, 40, 50];
