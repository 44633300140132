import { createContext, useContext, useState, useCallback, useEffect } from 'react';
import { useToast, useDisclosure } from '@chakra-ui/react';

import OrganizationsApi from '../../../../api/organizations';
import { useCompaniesOptions } from '../../../../hooks/useCompaniesOptions';

export const OrganizationsContext = createContext();

export const OrganizationsProvider = ({ children }) => {
  const [organizations, setOrganizations] = useState([]);
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [loaders, setLoaders] = useState({
    isTableLoading: false,
    isCreateEditLoading: false,
  });

  const toast = useToast();
  const { isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose } = useDisclosure();
  const { companiesOptions } = useCompaniesOptions({ showAll: false });

  const refreshOrganizations = useCallback(async () => {
    try {
      setLoaders((prevLoaders) => ({ ...prevLoaders, isTableLoading: true }));
      const res = await OrganizationsApi.getAll();
      setOrganizations(res.data);
    } catch (e) {
      console.error('Failed to fetch organizations', e);
      toast({
        title: 'שגיאה בטעינת אירגונים',
        description: 'אנא נסה שנית מאוחר יותר',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top',
      });
    } finally {
      setLoaders((prevLoaders) => ({ ...prevLoaders, isTableLoading: false }));
    }
  }, [toast]);

  const handleEditModalOpen = (organization = {}) => {
    const selectedCompanies =
      organization.companies?.map((company) => ({
        label: company?.name || 'N/A',
        value: String(company?.id || ''),
      })) || [];
    setSelectedOrganization({ ...organization, companies: selectedCompanies });
    setIsEditMode(true);
    onModalOpen();
  };

  const handleCreateModalOpen = () => {
    setIsEditMode(false);
    onModalOpen();
  };

  const handleError = (error) => {
    console.error('Failed: ', error);
    toast({
      title: 'שגיאה',
      description: 'אנא נסה שנית מאוחר יותר',
      status: 'error',
      duration: 3000,
      isClosable: true,
      position: 'top',
    });
  };

  const handleSuccess = () => {
    onModalClose();
    toast({
      title: 'הפעולה התבצעה בהצלחה',
      status: 'success',
      duration: 3000,
      isClosable: true,
      position: 'top',
    });
    refreshOrganizations();
  };

  const onSubmit = async (data) => {
    try {
      setLoaders((prevLoaders) => ({ ...prevLoaders, isCreateEditLoading: true }));
      const companyIds = data.companies.map((company) => Number(company.value));

      if (isEditMode) {
        await OrganizationsApi.update({ ...data, companies: companyIds });
      } else {
        await OrganizationsApi.create({ ...data, companies: companyIds });
      }
      handleSuccess();
    } catch (error) {
      handleError(error);
    } finally {
      setLoaders((prevLoaders) => ({ ...prevLoaders, isCreateEditLoading: false }));
    }
  };

  const onDelete = async (organizationId) => {
    try {
      await OrganizationsApi.delete(organizationId);
      handleSuccess();
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    refreshOrganizations();
  }, [refreshOrganizations]);

  return (
    <OrganizationsContext.Provider
      value={{
        loaders,
        organizations,
        selectedOrganization,
        isModalOpen,
        onModalClose,
        onSubmit,
        handleEditModalOpen,
        handleCreateModalOpen,
        onDelete,
        companiesOptions,
        isEditMode,
      }}
    >
      {children}
    </OrganizationsContext.Provider>
  );
};

export const useOrganizations = () => {
  return useContext(OrganizationsContext);
};
