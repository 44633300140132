import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormControl,
    FormLabel,
    Input,
    useToast,
    Text,
    VStack,
    HStack,
    IconButton,
    Flex,
    Checkbox,
    Box,
    InputGroup,
    FormHelperText,
} from "@chakra-ui/react";
import { AddIcon, DeleteIcon, AttachmentIcon } from "@chakra-ui/icons";
import React, { useState, useRef } from "react";
import ReportsApi from "../../../../api/reports";

export default function SendInvoiceEmailModal({ isOpen, onClose, report }) {
    const toast = useToast();
    const [emailAddresses, setEmailAddresses] = useState([{ value: "" }]);
    const [isSending, setIsSending] = useState(false);
    const [includeDeductionReport, setIncludeDeductionReport] = useState(true);
    const [deductionReportFile, setDeductionReportFile] = useState(null);
    const [deductionReportFileName, setDeductionReportFileName] = useState("");
    const fileInputRef = useRef(null);

    // Function to check if document name contains a specific string
    const hasDocumentWithName = (nameSubstring) => {
        if (!report?.documents || !Array.isArray(report.documents)) {
            return false;
        }
        return report.documents.some(doc =>
            doc.docName && doc.docName.includes(nameSubstring)
        );
    };

    // Parse document types from documentId JSON string
    const getDocumentTypes = () => {
        if (!report?.documentId) return [];

        try {
            const docArray = JSON.parse(report.documentId);
            return docArray.map(doc => doc.type);
        } catch (e) {
            console.error("Error parsing documentId:", e);
            return [];
        }
    };

    const documentTypes = getDocumentTypes();
    const hasEmployeeTransfersFeeInvoice = documentTypes.includes(1) || hasDocumentWithName("חשבונית מס עובדים");
    const hasAdvancePaymentRequest = documentTypes.includes(2) || hasDocumentWithName("דרישת תשלום");
    const hasCompanySubscriptionFeeInvoice = documentTypes.includes(4) || hasDocumentWithName("חשבונית דמי מנוי");

    const handleAddEmail = () => {
        setEmailAddresses([...emailAddresses, { value: "" }]);
    };

    const handleRemoveEmail = (index) => {
        const updatedEmails = [...emailAddresses];
        updatedEmails.splice(index, 1);
        setEmailAddresses(updatedEmails);
    };

    const handleEmailChange = (index, value) => {
        const updatedEmails = [...emailAddresses];
        updatedEmails[index].value = value;
        setEmailAddresses(updatedEmails);
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                file.type === "application/vnd.ms-excel" ||
                file.name.endsWith('.xlsx') ||
                file.name.endsWith('.xls')) {
                setDeductionReportFile(file);
                setDeductionReportFileName(file.name);
            } else {
                toast({
                    title: "סוג קובץ לא תקין",
                    description: "יש לבחור קובץ אקסל (.xlsx או .xls)",
                    position: "top",
                    status: "error",
                    isClosable: true,
                });
                // Clear the input
                if (fileInputRef.current) {
                    fileInputRef.current.value = "";
                }
            }
        }
    };

    const handleBrowseClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleRemoveFile = () => {
        setDeductionReportFile(null);
        setDeductionReportFileName("");
        if (fileInputRef.current) {
            fileInputRef.current.value = "";
        }
    };

    const handleSendEmail = () => {
        // Filter out empty email addresses
        const validEmails = emailAddresses
            .map(email => email.value.trim())
            .filter(email => email !== "");

        if (validEmails.length === 0) {
            toast({
                title: "נא להזין לפחות כתובת אימייל אחת",
                position: "top",
                status: "error",
                isClosable: true,
            });
            return;
        }

        // Validate email format
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const invalidEmails = validEmails.filter(email => !emailRegex.test(email));

        if (invalidEmails.length > 0) {
            toast({
                title: "כתובות אימייל לא תקינות",
                description: invalidEmails.join(", "),
                position: "top",
                status: "error",
                isClosable: true,
            });
            return;
        }

        // Check if deduction report is required but not provided
        if (includeDeductionReport && !deductionReportFile) {
            toast({
                title: "דו״ח ניכויים חסר",
                description: "סימנת לכלול דו״ח ניכויים, אך לא העלית קובץ",
                position: "top",
                status: "warning",
                isClosable: true,
            });
            return;
        }

        setIsSending(true);

        // Format the pay period to MM/YYYY
        const payPeriodParts = report.payPeriod.split("/");
        let formattedPayPeriod = report.payPeriod;

        // Ensure consistent MM/YYYY format
        if (payPeriodParts.length === 2) {
            // If already in MM/YYYY format, ensure month is 2 digits
            const month = payPeriodParts[0].padStart(2, '0');
            formattedPayPeriod = `${month}/${payPeriodParts[1]}`;
        }

        // Create FormData to send the file
        const formData = new FormData();
        formData.append('emailAddresses', JSON.stringify(validEmails));
        formData.append('companyId', report.companyId);
        formData.append('reportId', report.id);
        formData.append('payPeriod', formattedPayPeriod);
        formData.append('includeDeductionReport', includeDeductionReport.toString());

        if (includeDeductionReport && deductionReportFile) {
            formData.append('deductionReportFile', deductionReportFile);
        }

        ReportsApi.sendInvoiceEmailWithAttachment(formData)
            .then(response => {
                if (response.data.success) {
                    toast({
                        title: "אימייל נשלח בהצלחה",
                        description: `נשלח ל: ${validEmails.join(", ")}`,
                        position: "top",
                        status: "success",
                        isClosable: true,
                    });
                    setEmailAddresses([{ value: "" }]);
                    setDeductionReportFile(null);
                    setDeductionReportFileName("");
                    onClose();
                } else {
                    toast({
                        title: "שגיאה בשליחת האימייל",
                        description: response.data.error || "אירעה שגיאה בלתי צפויה",
                        position: "top",
                        status: "error",
                        isClosable: true,
                    });
                }
            })
            .catch(error => {
                toast({
                    title: "שגיאה בשליחת האימייל",
                    description: error.message || "אירעה שגיאה בלתי צפויה",
                    position: "top",
                    status: "error",
                    isClosable: true,
                });
            })
            .finally(() => {
                setIsSending(false);
            });
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            isCentered
            closeOnOverlayClick={false}
        >
            <ModalOverlay />
            <ModalContent maxW="500px">
                <ModalHeader>
                    שליחת מסמכים במייל
                    <Text fontSize="md" fontWeight="normal" mt={2}>
                        {report?.companyName} - {report?.payPeriod}
                    </Text>
                </ModalHeader>
                <ModalBody>
                    <VStack spacing={4} align="stretch">
                        <Text>
                            הזן את כתובות האימייל אליהן תרצה לשלוח את המסמכים הבאים:
                        </Text>
                        {hasEmployeeTransfersFeeInvoice && (
                            <Text fontWeight="bold">• חשבונית מס עבור עמלות עובדים</Text>
                        )}
                        {hasAdvancePaymentRequest && (
                            <Text fontWeight="bold">• דרישת תשלום (עבור מקדמות)</Text>
                        )}
                        {hasCompanySubscriptionFeeInvoice && (
                            <Text fontWeight="bold">• חשבונית מס עבור דמי מנוי</Text>
                        )}

                        <Box py={2}>
                            <Checkbox
                                isChecked={includeDeductionReport}
                                onChange={(e) => {
                                    setIncludeDeductionReport(e.target.checked);
                                    if (!e.target.checked) {
                                        // If unchecked, clear the file
                                        setDeductionReportFile(null);
                                        setDeductionReportFileName("");
                                        if (fileInputRef.current) {
                                            fileInputRef.current.value = "";
                                        }
                                    }
                                }}
                                colorScheme="blue"
                            >
                                <Text fontWeight="bold">צרף דו״ח ניכויים</Text>
                            </Checkbox>
                        </Box>

                        {includeDeductionReport && (
                            <FormControl>
                                <FormLabel>העלאת דו״ח ניכויים (XLSX):</FormLabel>
                                <InputGroup>
                                    <input
                                        type="file"
                                        ref={fileInputRef}
                                        onChange={handleFileChange}
                                        accept=".xlsx,.xls"
                                        style={{ display: 'none' }}
                                        disabled={isSending}
                                    />
                                    <Flex width="100%">
                                        <Button
                                            leftIcon={<AttachmentIcon />}
                                            onClick={handleBrowseClick}
                                            isDisabled={isSending}
                                            mr={2}
                                        >
                                            בחר קובץ
                                        </Button>
                                        <Input
                                            value={deductionReportFileName || "לא נבחר קובץ"}
                                            isReadOnly
                                            placeholder="לא נבחר קובץ"
                                        />
                                        {deductionReportFileName && (
                                            <IconButton
                                                icon={<DeleteIcon />}
                                                onClick={handleRemoveFile}
                                                aria-label="Remove file"
                                                colorScheme="red"
                                                ml={2}
                                                isDisabled={isSending}
                                            />
                                        )}
                                    </Flex>
                                </InputGroup>
                                <FormHelperText>יש לבחור קובץ אקסל (.xlsx או .xls)</FormHelperText>
                            </FormControl>
                        )}

                        <FormControl>
                            <FormLabel>כתובות אימייל:</FormLabel>
                            {emailAddresses.map((email, index) => (
                                <HStack key={index} mt={2}>
                                    <Input
                                        placeholder="example@company.com"
                                        value={email.value}
                                        onChange={(e) => handleEmailChange(index, e.target.value)}
                                        isDisabled={isSending}
                                    />
                                    {emailAddresses.length > 1 && (
                                        <IconButton
                                            icon={<DeleteIcon />}
                                            onClick={() => handleRemoveEmail(index)}
                                            aria-label="Remove email"
                                            colorScheme="red"
                                            isDisabled={isSending}
                                        />
                                    )}
                                </HStack>
                            ))}
                            <Flex justify="flex-end" mt={2}>
                                <Button
                                    leftIcon={<AddIcon />}
                                    onClick={handleAddEmail}
                                    size="sm"
                                    isDisabled={isSending}
                                >
                                    הוסף אימייל
                                </Button>
                            </Flex>
                        </FormControl>
                    </VStack>
                </ModalBody>
                <ModalFooter>
                    <Button
                        onClick={onClose}
                        mr={3}
                        isDisabled={isSending}
                    >
                        ביטול
                    </Button>
                    <Button
                        colorScheme="blue"
                        onClick={handleSendEmail}
                        isLoading={isSending}
                        loadingText="שולח..."
                    >
                        שלח
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}
