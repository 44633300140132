import { API } from './env';
import { axiosInstance } from './httpClient';
import { format } from 'date-fns';

export default class IntegrationsApi {
  static getAuthHeaders() {
    const userInfo = JSON.parse(localStorage.getItem('user'));
    const accessToken = userInfo.token;
    return {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + accessToken,
    };
  }

  static uploadTaFile = (file, providerId, companyId) => {
    let userInfo = JSON.parse(localStorage.getItem('user'));
    let accessToken = userInfo.token;
    let formData = new FormData();
    formData.append('file', file);
    formData.append('providerId', providerId);
    formData.append('companyId', companyId);
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + accessToken,
      },
    };
    return axiosInstance.post(`${API}/integrations/ta/upload/`, formData, config);
  };

  static getIntegrationsResults = (params, filters = {}) => {
    const { limit, page = 1 } = params;

    const getFiltersForApi = () => {
      const apiFilters = {};
      Object.entries(filters).forEach(([key, value]) => {
        if (value) {
          if (key === 'dates') {
            // Convert the dates to ISO 8601 format (e.g. 2000-12-31)
            if (value?.from) {
              apiFilters.startDate = format(value.from, 'yyyy-MM-dd');
            }

            if (value?.to) {
              apiFilters.endDate = format(value.to, 'yyyy-MM-dd');
            }
          } else {
            apiFilters[key] = value;
          }
        }
      });
      return apiFilters;
    };

    const config = {
      method: 'post',
      headers: IntegrationsApi.getAuthHeaders(),
      params: { limit, page },
    };

    return axiosInstance.post(
      `${API}/integrations/results`,
      JSON.stringify(getFiltersForApi()),
      config,
    );
  };

  static getIntegrationsErrors = (integrationResultId, companyId) => {
    let userInfo = JSON.parse(localStorage.getItem('user'));
    let accessToken = userInfo.token;
    const config = {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken,
      },
    };

    if (integrationResultId) {
      return axiosInstance.get(
        `${API}/integrations/errors?integrationResultId=` + integrationResultId,
        config,
      );
    } else if (companyId) {
      return axiosInstance.get(`${API}/integrations/errors?companyId=` + companyId, config);
    } else {
      return axiosInstance.get(`${API}/integrations/errors`, config);
    }
  };

  static triggerIntegrationAction = (integrationActionId) => {
    let userInfo = JSON.parse(localStorage.getItem('user'));
    let accessToken = userInfo.token;
    const config = {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken,
      },
    };

    if (integrationActionId) {
      return axiosInstance.get(
        `${API}/integrations/actions?integrationActionId=` + integrationActionId,
        config,
      );
    }
  };

  static triggerAttendanceIntegrationActionByCompanyId = (companyId) => {
    let userInfo = JSON.parse(localStorage.getItem('user'));
    let accessToken = userInfo.token;
    const config = {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken,
      },
    };

    if (companyId) {
      return axiosInstance.get(`${API}/integrations/actions?companyId=` + companyId, config);
    }
  };

  static setIntegrationErrorState = (errorId, state) => {
    let userInfo = JSON.parse(localStorage.getItem('user'));
    let accessToken = userInfo.token;
    let body = {
      errorId: String(errorId),
      state: state,
    };
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken,
      },
    };

    return axiosInstance.post(`${API}/integrations/errors`, JSON.stringify(body), config);
  };

  static downloadIntegrationFile = (integrationActionResultId) => {
    let userInfo = JSON.parse(localStorage.getItem('user'));
    let accessToken = userInfo.token;
    const config = {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken,
      },
    };

    if (integrationActionResultId) {
      return axiosInstance.get(
        `${API}/integrations/actions/download?integrationActionResultId=` +
          integrationActionResultId,
        config,
      );
    }
  };
}
