// Default = '0',
//     All = '1',
//     MissingPhoneNumber = '2',
//     MissingName = '3',
//     MissingGovId = '4',
//     MissingInternalId = '5',
//     MissingIntegrationUid = '6',
//     MissingBankAccountDetails = '7',

export const prettifyMissingInformationStatus = (code) => {
  switch (code) {
    case '2':
      return 'מספר טלפון';
    case '3':
      return 'שם';
    case '4':
      return 'תעודת זהות';
    case '5':
      return 'מספר עובד פנימי';
    case '6':
      return 'מזהה אינטגרציה';
    case '7':
      return 'פרטי חשבון בנק';
    case '8':
      return 'תאריך לידה';
    default:
      return 'unknown';
  }
};

/**
 * Checks if a value is non-empty, handling nested objects, arrays and Date objects
 * @param {any} value - The value to check
 * @returns {boolean} - True if the value is non-empty, false otherwise
 */
export const isValueNonEmpty = (value) => {
  if (!value) return false;
  if (value instanceof Date) return true;

  if (Array.isArray(value)) {
    return value.length > 0 && value.some((item) => isValueNonEmpty(item));
  }

  if (typeof value === 'object') {
    return Object.values(value).some((val) => isValueNonEmpty(val));
  }

  return Boolean(value);
};
